import {LitElement} from 'lit';
import i18next from 'i18next';
//import HttpApi from 'i18next-http-backend';
import en from '../../assets/locales/en.json';
import ar from '../../assets/locales/ar.json';
import fr from '../../assets/locales/fr.json';
import es from '../../assets/locales/es.json';
import zh from '../../assets/locales/zh.json';
import ru from '../../assets/locales/ru.json';
import ko from '../../assets/locales/ko.json';
import pt from '../../assets/locales/pt.json';
import ja from '../../assets/locales/ja.json';
import de from '../../assets/locales/de.json';

//import { baseUrl } from '../../env';

export class i18 extends LitElement {

  _translation_ready: boolean = true;

  // @property({
  //   hasChanged(newVal: string, oldVal: string) {
  //     let hasChanged = false;
  //     if(oldVal && newVal){
  //       hasChanged = newVal?.toLowerCase() !== oldVal?.toLowerCase();
  //       if (hasChanged) {
  //         i18next.changeLanguage(newVal);
  //       }
  //     }
  //     return hasChanged;
  //   },
  //   type: String
  // })
  lng;

  
  
  firstUpdated () {
    //const url = baseUrl;
    // let url = (<HTMLImageElement>document.querySelector('script[src*="' + 'index.js' + '"]')).src;
    // const regex = new RegExp('/[^/]*$');
    // const getUrl = url.replace(regex, '/');
    if (!i18next.isInitialized) {
      console.log('!i18next.isInitialized');
      i18next
      .init({
        resources: {
          fr : {
            translation: fr
            },
          ar : {
            translation: ar
          },
          en: {
            translation: en
          },
          es : {
            translation: es
            },
          zh : {
            translation: zh
          },
          ru: {
            translation: ru
          },
          ko: {
            translation: ko
          },
          pt: {
            translation: pt
          },
          ja: {
            translation: ja
          },
          de: {
            translation: de
          }  
        },
        debug: true, 
        lng: this.lng,
        fallbackLng: 'en'
    })
  }

  i18next.on('loaded', translations => {
      this._translation_ready = true;
      this.requestUpdate();
    
    console.log('----translations', translations);
  });
  i18next.on('languageChanged', options => {
    console.log('language----Changed', options);
    this.requestUpdate();
  });
  this.requestUpdate();
  }
  
}