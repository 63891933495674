import { html, TemplateResult} from 'lit';
import {customElement, query} from 'lit/decorators.js';
import * as utils from './utils/constants';
import * as logo from './utils/logo';
import  styles from './wipo-footer.scss';
import i18next, {
  t as translate,
} from 'i18next';
import  wipoGlobal from '../../style/global.scss';
import { i18 } from './i18';

@customElement('wipo-footer')
export class WipoFooter extends i18 {
  private _children: Element[];
  private _observer: MutationObserver;
  @query('#footer') private footer?: HTMLInputElement;
  // private _hasContactSlot = false;
  // private _hasMediaSlot = false;
  // private _hasLinksSlot = false;

  // @property({
  //   hasChanged(newVal: string, oldVal: string) {
  //     let hasChanged = false;
  //     if(oldVal && newVal){
  //       hasChanged = newVal?.toLowerCase() !== oldVal?.toLowerCase();
  //       console.log('hasChanged', hasChanged);
  //       if (hasChanged) {
  //         i18next.changeLanguage(newVal);
  //       }
  //     }
  //     return hasChanged;
  //   },
  //   type: String
  // })
  lng = 'en';

  changeLang(currentLang: string = 'en') {
    i18next.changeLanguage(currentLang);
  }

  langAttributeObserver() {
    this._observer = new MutationObserver( (MutationRecord, observer) => {
      console.log('mutationList', MutationRecord);
      console.log('observer', observer);
      MutationRecord.map((mutation) => {
        
        const langAtribute = mutation.target as HTMLElement;
        console.log('mutation', mutation);
        const languageSelected = langAtribute?.lang;
        languageSelected ? this.updateLanguageSwitchChanges(languageSelected) : null;
      })
    });
    const targetNode = document.getElementsByTagName('html')[0];
    const config = { attributes: true, childList: false, subtree: true };
    this._observer.observe(targetNode, config);

    targetNode.lang ? this.updateLanguageSwitchChanges(targetNode.lang, false) : this._updateSlotsVaule();;
    
  }

  getCurrentLanguage(name: string) {
    let currentLanguage = null;
    let cookieArr = document.cookie.split(';');

    cookieArr.forEach((ele) => {
      let cookie = ele.split('=');
      if (name == cookie[0].trim()) {
        currentLanguage = cookie[1];
      }
    });
    currentLanguage = this.setLanguage(currentLanguage);
    return currentLanguage;
  }

  setDirAttribute (language: string) {
    setTimeout(() => {
      if (language==='ar') {
        this.footer.setAttribute('dir', 'rtl');
      } else {
        this.footer.setAttribute('dir', 'ltr');
      }
    }, 0)
  }

  setLanguage(language: string){
    const additonalLanguages = ['ko', 'pt', 'ja', 'de'];
    if (additonalLanguages.includes(language)) {
      language = 'en';
    }
    return language;
  }

  updateLanguageSwitchChanges (language: string = 'en', changeLang: boolean = true) {
    this.lng = this.setLanguage(language);
    this.setDirAttribute(language);
    changeLang ? this.changeLang(language) : null;
    this._updateSlotsVaule();
  }

  connectedCallback() {
    super.connectedCallback();
    this.lng = this.getCurrentLanguage('wipo_language') || 'en';
    this.setDirAttribute(this.lng);

    document.addEventListener('wipoLanguageChange', (e: any) => {
      this.updateLanguageSwitchChanges(e.detail.languageSelected);
    });

    this.langAttributeObserver();
  }

  disconnectedCallback () {
    this._observer.disconnect();
  }

  _updateSlotsVaule () {
    this._children = Array.from(this.children);
    this._children.forEach((child) => {
      child.getAttribute('slot') === 'contact'
        ? (utils.URLS.CONTACT = child.innerHTML)
        : null;
      child.getAttribute('slot') === 'media'
        ? (utils.URLS.MEDIA = child.innerHTML)
        : null;
      if (utils.SMSlots.includes(child.getAttribute('slot'))) {
        this._setHyperLink(
          'smIcons',
          child.getAttribute('slot'),
          child.innerHTML
        );
      }
      if (utils.otherLinksSlot.includes(child.getAttribute('slot'))) {
        this._setHyperLink(
          'otherLinks',
          child.getAttribute('slot'),
          child.innerHTML
        );
      }
    });
    this.requestUpdate();
  }

  _setHyperLink(listName: string, iconName: string, link: string): void {
    const icon = utils[listName].filter((ele) => ele.slotName === iconName)[0];
    icon.link = link;
  }

  _setLinks(url: string): string {
    const hyperLink = url.replace('{{lang}}', this.lng);
    return `${hyperLink}`;
  }

  static styles = [styles, wipoGlobal];

  footerLogo() {
    return html` <a href="https://www.wipo.int/portal/${this.lng}" target="_blank" id="wipoLogoBlack">${logo[this.lng]}</a> `;
  }

  socialMedia() {
    return html`
      <ul class="footer-list">
        ${utils.smIcons.map((ele) => {
          const link = ele.link.replace('{{lang}}', this.lng);
          return html`<li>
            <a href=${link} title="${translate(`footer.${ele.name}`)}" target="_blank">${utils[`footer_${ele.name}`]}</a>
          </li>`;
        })}
      </ul>
    `;
  }

  otherLinks() {
    return html`
      <ul>
        ${utils.otherLinks.map((ele) => {
          const link = ele.link.replace('{{lang}}', this.lng);
          return html`<li>
            <a href=${link} target="_blank">${translate(`footer.${ele.text}`)}</a>
          </li>`;
        })}
      </ul>
    `;
  }

  footerTemplate() {
    return html` <div class="footer-bg" id="footer">
      <footer class="wipo-footer">
        <div class="footer-wrapper">
        <div class="footer-container blue-button">
          <div class="footer-logo">
            ${this.footerLogo()}
            <p class="small footer-logo-copy">${translate('footer.text')}</p>
          </div>
          <div class="footer-media-contact">
            <p>
              <a href="${this._setLinks(utils.URLS.MEDIA)}" target="_blank"
                >${translate('footer.media')}</a
              >
              <a href=${this._setLinks(utils.URLS.CONTACT)} target="_blank"
                >${translate('footer.contact')}</a
              >
            </p>
            ${this.socialMedia()}
          </div>
        </div>
        <div class="footer-bottom">
            ${this.otherLinks()}
            </div>
        </div>
      </footer>
    </div>`;
  }

  render(): TemplateResult {
    if (this._translation_ready) {
      return html`${this.footerTemplate()}`;
    } else {
      return html`...loading`;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'wipo-footer': WipoFooter;
  }
}
