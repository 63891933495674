import { html } from "lit-html";

export const en = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 238.4 167" style="enable-background:new 0 0 238.4 167;" xml:space="preserve">
<path d="M0,105.9h4.3l3.1,11.6l0,0l3.3-11.6H15l3.3,11.6l0,0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3l0,0l-3.4,11.3H5L0,105.9z"/>
<path d="M30.1,105.9h4.3v15.9h-4.3C30.1,121.8,30.1,105.9,30.1,105.9z"/>
<path d="M40.8,105.9h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4h-6.7v5.7h-4.3V105.9z M45.1,113.3h4.6c1.3,0,2.9,0,2.9-2.2
	s-1.3-2.3-2.5-2.3h-5V113.3z"/>
<path d="M71.5,105.5c8.6,0,9.9,5.1,9.9,8.4s-1.3,8.4-9.9,8.4s-9.9-5.1-9.9-8.4S63,105.5,71.5,105.5z M71.5,119.3
	c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S67.6,119.3,71.5,119.3z"/>
<path d="M79.2,20.1c1.3-1.4,2-3.3,2.1-5.2c-0.1-6-8.2-10.9-21-14.8L59.9,0v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4s5.2,4.1,5.2,6.2
	c0,1.5-0.8,3.1-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L60,10.2v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3
	c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4
	c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.2l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V47c15.1-2,27.9-5,36.9-8.8
	c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.5,1.2,3.7,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
	V43l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9
	c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L59.9,51v2.1l0.3,0.1
	c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9
	c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L60,61.2v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
	c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.9-5,36.9-8.8c5.3-2.2,9.3-4.6,11.8-7.2c0.8,0.9,1.3,2,1.3,3.3
	c0.1,3.6-4.9,7.3-13.8,10.4S45.2,91,30.3,92.7V98c15.1-2,27.9-5,36.9-8.8c9-3.7,14.4-8.1,14.4-13.1c-0.1-1.9-0.8-3.7-2.1-5.1
	c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5.1c1.2-1.5,1.9-3.3,2-5.2
	c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2C81.2,23.3,80.5,21.5,79.2,20.1z
	 M72.5,25.3c2.3-1.2,4.3-2.7,6.1-4.5c0.8,0.9,1.3,2,1.3,3.3c0,1.5-0.8,3.1-2.5,4.5C75.9,27.3,74.3,26.2,72.5,25.3z M80,64.8
	c0,1.5-0.8,3.1-2.5,4.5C76,68,74.3,66.9,72.6,66c2.3-1.2,4.3-2.7,6.1-4.5C79.5,62.5,80,63.6,80,64.8L80,64.8z M80,54.6
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,52.3,80,53.4,80,54.6L80,54.6z M80,44.4
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,42.1,80,43.2,80,44.4L80,44.4z M80,34.2
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,31.9,80,33,80,34.2L80,34.2z"/>
<path d="M29.1,130.7h1.8l1.7,6l0,0l1.9-6h1.7l1.9,6l0,0l1.8-6h1.7l-2.6,7.8h-1.7l-1.9-6l0,0l-1.9,6h-1.8L29.1,130.7z"/>
<path d="M43.3,134.6c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C44.6,138.6,43.3,136.7,43.3,134.6z M50.7,134.6
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C50.7,134.8,50.7,134.7,50.7,134.6z"/>
<path d="M54.8,130.7h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.8-0.5,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L54.8,130.7z M59.2,134.1c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L59.2,134.1L59.2,134.1z"/>
<path d="M64.9,130.7h1.6v6.5h4.7v1.3h-6.3C64.9,138.5,64.9,130.7,64.9,130.7z"/>
<path d="M73.4,130.7h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6V130.7z M75,137.2h2.3c1.2,0,2.4-0.4,2.4-2.7
	c0-2.5-1.6-2.7-2.5-2.7H75V137.2z"/>
<path d="M30,144.8h2.6v7.8H30V144.8z"/>
<path d="M35.4,144.8h3l3.6,4.9l0,0v-4.9h2.5v7.8h-2.9l-3.7-4.9l0,0v4.9h-2.6v-7.8H35.4z"/>
<path d="M46.5,144.8h8.3v1.7H52v6h-2.6v-6h-2.8v-1.7H46.5z"/>
<path d="M56.8,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8C56.8,152.6,56.8,144.8,56.8,144.8z"/>
<path d="M66.9,144.8h2.6v5.9h4.7v1.8h-7.3L66.9,144.8z"/>
<path d="M76.4,144.8H79v5.9h4.8v1.8h-7.3L76.4,144.8L76.4,144.8z"/>
<path d="M85.9,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M105.4,149.6c-0.3,1.7-1.2,3.3-4.9,3.3c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.5,4.9,3.3h-2.9
	c-0.2-0.9-1-1.6-2-1.5c-1.6,0-2.2,1.1-2.2,2.4c0,1.3,0.5,2.4,2.2,2.4c1,0.1,1.9-0.5,2-1.5L105.4,149.6z"/>
<path d="M106.9,144.8h8.3v1.7h-2.9v6h-2.6v-6h-2.8V144.8z"/>
<path d="M117.1,144.8h2.6v4.6c0,1.3,0.6,1.6,1.8,1.6c1.2,0,1.8-0.4,1.8-1.6v-4.6h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3V144.8z"/>
<path d="M130.8,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L130.8,144.8z M132.3,146.8L132.3,146.8l-1.1,2.9h2.2
	L132.3,146.8z"/>
<path d="M139,144.8h2.6v5.9h4.7v1.8H139V144.8z"/>
<path d="M153.9,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L153.9,144.8L153.9,144.8z M156.5,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M164.8,144.8h6.3c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4H171
	c-0.1-0.4-0.2-0.8-0.1-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6L164.8,144.8z M167.4,148.3h2.4c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.4V148.3z"/>
<path d="M180.8,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1c-2.7,0-5.1-0.9-5.1-4.1S178,144.6,180.8,144.6z M180.8,151.1
	c1.3,0,2.4-1,2.5-2.3c0-1.3-1-2.4-2.3-2.5c-1.3,0-2.4,1-2.5,2.3c0,0,0,0,0,0.1c-0.1,1.2,0.7,2.2,1.9,2.4
	C180.5,151.1,180.6,151.1,180.8,151.1L180.8,151.1z"/>
<path d="M188.2,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L188.2,144.8L188.2,144.8z M190.8,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M199.1,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M209.2,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
	c-0.1-0.4-0.2-0.8-0.2-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6v-7.8H209.2z M211.8,148.3h2.5c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.5V148.3z"/>
<path d="M219.7,144.8h8.3v1.7h-2.8v6h-2.6v-6h-2.8L219.7,144.8L219.7,144.8z"/>
<path d="M232.3,150.2l-3.5-5.3h3l1.7,3.3l1.7-3.3h3l-3.5,5.3v2.4h-2.6L232.3,150.2z"/>
<path d="M29.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1S37,167,33.7,167S29.1,165.1,29.1,162.9z M36.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C36.6,163.2,36.6,163,36.6,162.9z"/>
<path d="M40.7,159h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.9-0.4,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L40.7,159L40.7,159z M45.1,162.5c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L45.1,162.5z"/>
<path d="M59,166.8h-1l-0.2-1c-0.8,0.8-1.8,1.3-3,1.2c-3.3,0-4.6-1.9-4.6-4.1c0-2.2,1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4.1,2.7H57
	c-0.3-1-1.3-1.6-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c-0.2,1.4,0.8,2.7,2.2,2.8c0.2,0,0.3,0,0.5,0c1.6,0,2.4-0.8,2.7-2H55v-1.2h4V166.8z"/>
<path d="M64.4,159h1.9l3.5,7.8H68l-0.8-1.9h-3.6l-0.8,1.9H61L64.4,159z M66.7,163.6l-1.3-3.3l-1.3,3.3H66.7z"/>
<path d="M71.7,159h1.9l4.6,5.8l0,0V159h1.6v7.8H78l-4.7-5.8l0,0v5.8h-1.6V159z"/>
<path d="M82.6,159h1.6v7.8h-1.6V159z"/>
<path d="M86.4,165.6l5.3-5.4h-5V159h7.1v1.2l-5.3,5.3H94v1.3h-7.5L86.4,165.6z"/>
<path d="M98.7,159h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.6l-0.8,1.9h-1.8L98.7,159z M101,163.6l-1.3-3.3l-1.3,3.3H101z"/>
<path d="M107.5,160.2h-2.9V159h7.5v1.2h-2.9v6.5h-1.6L107.5,160.2L107.5,160.2z"/>
<path d="M114.1,159h1.6v7.8h-1.6V159z"/>
<path d="M118.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1S118.1,165.1,118.1,162.9z M125.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C125.6,163.2,125.6,163,125.6,162.9
	L125.6,162.9z"/>
<path d="M129.6,159h1.9l4.6,5.8l0,0V159h1.6v7.8h-1.8l-4.7-5.8l0,0v5.8h-1.6V159z"/>
</svg>
`;

export const fr = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 216.6 167" style="enable-background:new 0 0 216.6 167;" xml:space="preserve">
<g>
	<g>
		<path d="M73.4,20.1c1.3-1.6,2.1-3.4,2.1-5.2c-0.1-6.1-8.2-11-21-14.8L54,0v0.5v1.7l0.3,0.1C60.6,3.8,65.6,5.6,69,7.6
			c3.4,2,5.2,4.1,5.2,6.2c0,1.5-0.8,3-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L54,10.2v0.5v1.7l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.3
			c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,20.4v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
			c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2
			c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,30.6v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3.1-21.2,5.6-35.9,7.2V47
			c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4
			c-2.4-1-5.1-1.9-8-2.8L54,40.8v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8
			c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-2-8-2.8L54,51v0.5
			v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9
			c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,61.2v0.5v1.7l0.3,0.1
			c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.9
			c0.7,0.4,1.3,0.8,1.9,1.2c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.8-5,36.9-8.8
			c5.3-2.2,9.3-4.6,11.7-7.2c0.9,1.1,1.3,2.2,1.3,3.2c0.1,3.6-4.9,7.3-13.8,10.4C51.5,88.5,39,91,24.2,92.7V98
			C39.2,96,52,93,61,89.2c9-3.7,14.4-8.1,14.4-13.1c0-1.8-0.7-3.5-2.1-5.1c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5
			c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2
			c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2C75.4,23.4,74.7,21.7,73.4,20.1z M66.7,25.3c2.6-1.4,4.6-3,6.1-4.5
			c0.9,1.1,1.3,2.1,1.3,3.2c0,1.5-0.9,3-2.5,4.5C70.3,27.4,68.6,26.3,66.7,25.3z M74.1,64.8c0,1.5-0.9,3.1-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,62.7,74.1,63.7,74.1,64.8z M74.1,54.6c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,52.5,74.1,53.5,74.1,54.6z M74.1,44.4c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,42.2,74.1,43.3,74.1,44.4z M74.1,34.2c0,1.5-0.9,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,32,74.1,33.1,74.1,34.2z"/>
	</g>
	<g>
		<path d="M39.3,105.9h6.1v15.9h-4v-12.7h0l-4.8,12.7h-3.5l-4.8-12.7h0v12.7h-4v-15.9h6.1l4.5,11.4L39.3,105.9z"/>
		<path d="M51.1,105.9h10.3c4.3,0,5.4,2.7,5.4,4.8c0,3.4-1.7,5.4-5.1,5.4h-6.4v5.7h-4.2V105.9z M55.2,113.2h4.5
			c1.3,0,2.8-0.1,2.8-2.2c0-2.1-1.2-2.2-2.4-2.2h-4.8V113.2z"/>
		<path d="M71.2,105.9h4.2v15.9h-4.2V105.9z"/>
		<path d="M9.9,105.5c8.6,0,9.9,5.1,9.9,8.4c0,3.3-1.3,8.4-9.9,8.4S0,117.2,0,113.9S1.3,105.5,9.9,105.5z M9.9,119.2
			c3.9,0,5.3-2.9,5.3-5.4c0-2.5-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4C4.6,116.3,6,119.2,9.9,119.2z"/>
	</g>
	<g>
		<path d="M23.8,134.5c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C25,138.6,23.8,136.7,23.8,134.5z M31.2,134.5
			c0-1.7-1-2.9-2.9-2.9c-1.8,0-2.9,1.2-2.9,2.9s1,2.9,2.9,2.9C30.2,137.4,31.2,136.2,31.2,134.5z"/>
		<path d="M35.4,130.6h4.9c2,0,2.6,0.9,2.6,2.1c0,1-0.6,1.6-1.3,1.9v0c0.9,0.2,1.1,1,1.1,2.1c0,0.4,0,1.2,0.3,1.7h-1.8
			c-0.1-0.2-0.2-0.6-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9H37v3.1h-1.6C35.4,138.4,35.4,130.6,35.4,130.6z M39.8,134.1
			c0.9,0,1.4-0.3,1.4-1.1c0-0.8-0.5-1.1-1.4-1.1H37v2.3L39.8,134.1L39.8,134.1z"/>
		<path d="M53.8,138.4h-1l-0.2-1c-0.6,0.8-1.7,1.2-3,1.2c-3.3,0-4.6-1.9-4.6-4.1s1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4,2.7h-1.8
			c-0.3-1.1-1.3-1.5-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c0,2.1,1.3,2.8,2.7,2.8c1.7,0,2.4-0.8,2.7-2.1H50v-1.2h3.8L53.8,138.4L53.8,138.4z
			"/>
		<path d="M59.3,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L59.3,130.6z M61.6,135.2l-1.3-3.3l-1.3,3.3H61.6z"/>
		<path d="M66.7,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8h-1.6V130.6z"/>
		<path d="M77.7,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M85.3,130.4c1.2,0,3.6,0.2,3.8,2.5h-1.8c-0.1-0.6-0.6-1.2-2-1.2c-1.1,0-1.8,0.3-1.8,1.1c0,1.8,5.8,0.1,5.8,3.4
			c0,2-2.1,2.5-3.7,2.5c-2.8,0-3.9-1.1-3.9-2.7h1.8c0,1,0.9,1.5,2.2,1.5c1,0,1.9-0.3,1.9-1.1c0-1.9-5.7-0.2-5.7-3.4
			C81.8,131.1,83.4,130.4,85.3,130.4z"/>
		<path d="M94.2,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L94.2,130.6z M96.4,135.2l-1.3-3.3l-1.3,3.3H96.4z"/>
		<path d="M102.7,131.8h-2.9v-1.2h7.5v1.2h-2.9v6.6h-1.6C102.7,138.4,102.7,131.8,102.7,131.8z"/>
		<path d="M109.3,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M113.4,134.5c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C114.6,138.6,113.4,136.7,113.4,134.5z M120.8,134.5
			c0-1.7-1.1-2.9-2.9-2.9c-1.8,0-2.9,1.2-2.9,2.9s1.1,2.9,2.9,2.9C119.8,137.4,120.8,136.2,120.8,134.5z"/>
		<path d="M125,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8H125L125,130.6L125,130.6z"/>
		<path d="M141.1,130.6h2.4l2.7,6l2.7-6h2.3v7.8h-1.5l0.1-6.4h0l-2.9,6.4h-1.4l-2.9-6.4h0l0.1,6.4h-1.5L141.1,130.6L141.1,130.6z"/>
		<path d="M153.7,134.5c0-2.2,1.3-4.1,4.6-4.1c3.3,0,4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C155,138.6,153.7,136.7,153.7,134.5z
			 M161.1,134.5c0-1.7-1.1-2.9-2.9-2.9s-2.9,1.2-2.9,2.9s1.1,2.9,2.9,2.9C160.1,137.4,161.1,136.2,161.1,134.5z"/>
		<path d="M165.3,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8h-1.6V130.6z"/>
		<path d="M176.3,130.6h4.2c3.1,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6L176.3,130.6L176.3,130.6z M177.9,137.1h2.3
			c1.2,0,2.4-0.4,2.4-2.7c0-2.5-1.6-2.7-2.5-2.7h-2.2L177.9,137.1L177.9,137.1z"/>
		<path d="M186.8,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M193.9,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9H193l-0.8,1.9h-1.8L193.9,130.6z M196.2,135.2l-1.3-3.3l-1.4,3.3H196.2z"/>
		<path d="M201.3,130.6h1.6v6.5h4.7v1.3h-6.4V130.6z"/>
		<path d="M209.8,130.6h6.8v1.2h-5.1v2h4.9v1.2h-4.9v2.2h5.2v1.2h-6.8L209.8,130.6L209.8,130.6z"/>
		<path d="M24.2,144.8h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6L24.2,144.8L24.2,144.8z M25.8,151.3h2.3
			c1.2,0,2.4-0.4,2.4-2.7c0-2.5-1.6-2.7-2.5-2.7h-2.2C25.8,146,25.8,151.3,25.8,151.3z"/>
		<path d="M34.7,144.8h6.8v1.2h-5.1v2h4.9v1.2h-4.9v2.2h5.2v1.2h-6.8V144.8z"/>
		<path d="M49.3,144.8h1.6v6.5h4.7v1.3h-6.4V144.8z"/>
		<path d="M60.7,144.8h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L60.7,144.8z M63,149.4l-1.3-3.3l-1.3,3.3H63z"/>
		<path d="M73.3,144.8H79c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.8,2.6h-3.3v2.6h-2.6C73.3,152.6,73.3,144.8,73.3,144.8z
			 M75.9,148.4h2.3c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
		<path d="M84,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0,1-0.6,1.6-1.2,1.8v0c0.5,0.2,0.9,0.6,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
			c-0.1-0.3-0.2-0.8-0.2-1.1c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6H84V144.8z M86.6,148.2h2.5c0.6,0,1-0.3,1-0.9c0-0.5-0.3-0.8-1-0.8h-2.5
			V148.2z"/>
		<path d="M99.9,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1s-5.1-0.9-5.1-4.1C94.8,145.4,97.2,144.6,99.9,144.6z
			 M99.9,151.1c1.4,0,2.3-0.8,2.3-2.4c0-1.6-0.9-2.4-2.3-2.4c-1.4,0-2.3,0.8-2.3,2.4C97.6,150.3,98.5,151.1,99.9,151.1z"/>
		<path d="M107.3,144.8h5.8c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.8,2.6h-3.3v2.6h-2.6C107.3,152.6,107.3,144.8,107.3,144.8z
			 M109.9,148.4h2.3c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
		<path d="M118,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0,1-0.5,1.6-1.2,1.8v0c0.5,0.2,0.9,0.6,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
			c-0.1-0.3-0.2-0.8-0.2-1.1c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6H118L118,144.8L118,144.8z M120.6,148.2h2.5c0.6,0,1-0.3,1-0.9
			c0-0.5-0.3-0.8-1-0.8h-2.5V148.2z"/>
		<path d="M129.3,144.8h2.6v7.8h-2.6V144.8z"/>
		<path d="M134.6,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z M137.9,142.6h2.8l-1.6,1.5h-2.2L137.9,142.6z"/>
		<path d="M144.1,144.8h8.3v1.7h-2.9v6H147v-6h-2.9L144.1,144.8L144.1,144.8z"/>
		<path d="M154.4,144.8h7.8v1.7H157v1.4h4.9v1.6H157v1.4h5.2v1.7h-7.8V144.8z M157.7,142.6h2.8l-1.6,1.5h-2.2L157.7,142.6z"/>
		<path d="M24.2,159h2.6v7.8h-2.6V159z"/>
		<path d="M29.7,159h3l3.6,4.9h0V159h2.5v7.8H36l-3.7-4.9h0v4.9h-2.5V159z"/>
		<path d="M41,159h8.3v1.7h-2.8v6h-2.6v-6H41L41,159L41,159z"/>
		<path d="M51.5,159h7.8v1.7H54v1.4h4.9v1.6H54v1.4h5.2v1.7h-7.8V159z"/>
		<path d="M61.9,159h2.6v6h4.7v1.8h-7.3C61.9,166.8,61.9,159,61.9,159z"/>
		<path d="M71.5,159H74v6h4.7v1.8h-7.3C71.5,166.8,71.5,159,71.5,159z"/>
		<path d="M81,159h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7H81V159z"/>
		<path d="M100.8,163.8c-0.3,1.7-1.2,3.2-4.9,3.2c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.6,4.9,3.2h-2.9
			c-0.2-0.6-0.5-1.5-2-1.5c-1.6,0-2.2,1.1-2.2,2.4s0.5,2.4,2.2,2.4c1.2,0,1.9-0.6,2-1.5L100.8,163.8L100.8,163.8z"/>
		<path d="M102.5,159h8.3v1.7H108v6h-2.6v-6h-2.8L102.5,159L102.5,159z"/>
		<path d="M112.9,159h2.6v4.6c0,1.3,0.6,1.7,1.8,1.7s1.8-0.4,1.8-1.7V159h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3L112.9,159
			L112.9,159z"/>
		<path d="M124.5,159h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V159z"/>
		<path d="M134.9,159h2.6v6h4.7v1.8h-7.3V159z"/>
		<path d="M144.5,159h2.6v6h4.7v1.8h-7.3V159z"/>
		<path d="M154.1,159h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V159z"/>
	</g>
</g>
</svg>
`;

export const ar = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 174.3 167" style="enable-background:new 0 0 174.3 167;" xml:space="preserve">
<g id="XMLID_594_">
	<g id="XMLID_2_">
		<path id="XMLID_1550_" d="M0,108.5h4.4l3.2,11.9l0,0l3.3-11.9h4.4l3.3,11.9l0,0l3.2-11.9h4.4L21,124.8h-4.6L13,113.2l0,0
			l-3.4,11.6H5.2L0,108.5z"/>
		<path id="XMLID_1552_" d="M30.8,108.5h4.4v16.3h-4.4C30.8,124.8,30.8,108.5,30.8,108.5z"/>
		<path id="XMLID_1554_" d="M41.8,108.5h11c4.6,0,5.8,2.8,5.8,5c0,3.5-1.8,5.5-5.4,5.5h-6.9v5.8h-4.4L41.8,108.5L41.8,108.5z
			 M46.2,116.1H51c1.4,0,3,0,3-2.2c0-2.1-1.3-2.3-2.6-2.3h-5.2V116.1z"/>
		<path id="XMLID_1557_" d="M73.3,108.1c8.8,0,10.1,5.2,10.1,8.6s-1.4,8.6-10.1,8.6c-8.8,0-10.1-5.2-10.1-8.6
			C63.1,113.3,64.5,108.1,73.3,108.1z M73.3,122.2c4,0,5.4-3,5.4-5.5s-1.4-5.5-5.4-5.5s-5.4,3-5.4,5.5
			C67.8,119.2,69.3,122.2,73.3,122.2z"/>
	</g>
	<path id="XMLID_1561_" d="M81.2,20.6c1.4-1.7,2.1-3.4,2.1-5.3c0-6.2-8.4-11.2-21.4-15.2L61.4,0v0.5v1.7l0.3,0.1
		c6.4,1.6,11.5,3.5,15,5.5S82,12,82,14.2c0,1.6-0.9,3.1-2.6,4.7c-3.7-3.1-9.8-5.9-17.5-8.2l-0.5-0.1v0.5v1.7l0.3,0.1
		c6.4,1.6,11.5,3.4,15,5.5c0.7,0.4,1.3,0.8,1.9,1.3c-2,1.6-4.9,3.1-8.5,4.5c-2.5-1-5.2-2-8.2-2.9l-0.5-0.1v0.5v1.7l0.3,0.1
		c2.1,0.5,4.1,1.1,6,1.7c-9,3.1-21.7,5.7-36.8,7.4V38c15.4-2,28.5-5.2,37.8-9c1.5-0.6,2.9-1.3,4.2-1.9c1.5,0.6,2.7,1.3,3.9,1.9
		c0.7,0.4,1.3,0.8,1.9,1.3c-2,1.6-4.9,3.1-8.5,4.5c-2.5-1-5.2-2-8.2-2.9l-0.5-0.1v0.5V34l0.3,0.1c2.1,0.5,4.1,1.1,6,1.7
		c-9,3.1-21.7,5.7-36.8,7.4v5.4c15.4-2,28.5-5.2,37.8-9c1.5-0.6,2.9-1.3,4.3-2c1.4,0.6,2.7,1.3,3.9,1.9c0.7,0.4,1.3,0.8,1.9,1.3
		c-2,1.6-4.9,3.1-8.5,4.5c-2.5-1-5.2-2-8.2-2.9l-0.5-0.1v0.5V44l0.3,0.1c2.1,0.5,4.1,1.1,6,1.7c-9,3.1-21.7,5.7-36.8,7.4v5.4
		c15.5-2,28.5-5.2,37.8-9c1.5-0.6,2.9-1.3,4.3-1.9c1.5,0.6,2.7,1.2,3.9,1.9c0.7,0.4,1.3,0.8,1.9,1.3c-2,1.6-4.9,3.1-8.5,4.5
		c-2.5-1-5.2-2-8.2-2.9l-0.5-0.1v0.5v1.7l0.3,0.1c2.1,0.5,4.1,1.1,6,1.7c-9,3.1-21.7,5.7-36.8,7.4v5.4c15.5-2,28.5-5.2,37.8-9
		c1.5-0.6,2.9-1.3,4.3-1.9c1.5,0.6,2.7,1.2,3.9,1.9c0.7,0.4,1.3,0.8,1.9,1.3c-2,1.6-4.9,3.1-8.5,4.5c-2.5-1-5.2-2-8.2-2.9L62.1,63
		v0.5v1.7l0.3,0.1c2.1,0.5,4.1,1.1,6,1.7c-9,3.1-21.7,5.7-36.8,7.4v5.4c15.5-2,28.5-5.2,37.8-9c1.5-0.6,2.9-1.3,4.3-2
		c1.4,0.6,2.7,1.3,3.9,1.9c0.7,0.4,1.3,0.8,1.9,1.3c-2.4,1.9-6,3.7-10.7,5.3c-9,3.2-21.9,5.8-37.1,7.5V90c15.4-2,28.5-5.2,37.8-9
		c5.4-2.2,9.5-4.7,12-7.4c0.9,1.1,1.4,2.2,1.4,3.3c0.1,3.7-5,7.5-14.1,10.6c-9,3.2-21.9,5.8-37.1,7.5v5.4c15.4-2,28.5-5.2,37.8-9
		c9.2-3.8,14.7-8.3,14.7-13.4c0-1.8-0.8-3.5-2.1-5.2c1.4-1.7,2.1-3.4,2.1-5.3c0-1.8-0.8-3.5-2.1-5.2c1.4-1.7,2.1-3.4,2.1-5.3
		c0-1.8-0.8-3.6-2.1-5.2c1.4-1.7,2.1-3.4,2.1-5.3c0-1.8-0.8-3.5-2.1-5.2c1.4-1.7,2.1-3.4,2.1-5.3c0-1.8-0.8-3.5-2.1-5.2
		c1.4-1.7,2.1-3.4,2.1-5.3C83.3,24,82.6,22.2,81.2,20.6z M74.4,25.9c2.6-1.5,4.7-3,6.3-4.6c0.9,1.1,1.4,2.2,1.4,3.3
		c0,1.6-0.9,3.1-2.6,4.7C78,28.1,76.4,27,74.4,25.9z M82,66.4c0,1.6-0.9,3.1-2.6,4.7c-1.4-1.2-3.1-2.3-5-3.3c2.6-1.5,4.7-3,6.3-4.6
		C81.5,64.2,82,65.3,82,66.4z M82,56c0,1.6-0.9,3.1-2.6,4.7c-1.4-1.2-3.1-2.3-5-3.3c2.6-1.5,4.7-3,6.3-4.7C81.5,53.8,82,54.9,82,56z
		 M82,45.5c0,1.6-0.9,3.1-2.6,4.7c-1.4-1.2-3.1-2.3-5-3.3c2.6-1.5,4.7-3,6.3-4.7C81.5,43.3,82,44.4,82,45.5z M82,35.1
		c0,1.6-0.9,3.1-2.6,4.7c-1.4-1.2-3.1-2.3-5-3.3c2.6-1.5,4.7-3,6.3-4.6C81.5,32.8,82,34,82,35.1z"/>
	<g id="XMLID_1_">
		<path id="XMLID_1569_" d="M92.5,151.7H92v0.5v7.6c0,0.8-0.4,0.8-0.5,0.8h-7c0.1-0.4,0.1-0.9,0.1-1.3v-0.1v-3.9v-0.5H84h-3.5
			c-2,0-3.2,1.1-3.2,3v2.9h-5.7h-2.8c0-0.3,0-0.6,0-0.9v-0.2v-2.2c0-1.3-1-2.6-3-2.6l0,0l0,0h-1h-1c0-0.3,0-0.4,0-0.6
			c0-0.1,0.1-0.2,0.1-0.3c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.4-0.2,0.7-0.2h0.2l0,0h0.2h2.2l0,0H68l0,0v-0.5
			v-0.6v-0.5l0,0h-0.5l0,0h-2.6l0,0c-0.5,0-0.9,0.1-1.3,0.2c-1.1,0.4-1.7,1.3-1.7,2.8v2v0.5h0.5h3.5c0.2,0,0.4,0,0.5,0
			s0.2,0.1,0.2,0.3c0,0.1,0,0.3,0,0.5v2.5c0,0.1-0.2,0.2-0.3,0.2h-1.8h-9.6v-5.4v-0.5h-0.5H53h-0.5v0.5v7.6c0,1.3-0.3,1.4-1.3,1.8
			l-0.2,0.1l-0.6,0.3l0.4,0.5c0.2,0.2,0.7,0.9,0.9,1.2l0.2,0.3l0.4-0.2c2.3-1,2.6-2.4,2.6-3.5l0.8-0.2h8.7h7.2h20.7c1.3,0,2-1.6,2-3
			v-7.8v-0.5h-0.5h-1.3V151.7z M82.1,160.3c0,0.2-0.1,0.3-0.1,0.4l0,0h-2.2v-3.1c0-0.4,0.1-0.4,0.4-0.4h1.9
			C82.1,157.2,82.1,160.3,82.1,160.3z"/>
		<polygon id="XMLID_1572_" points="99.1,151.7 98.6,151.7 98.6,152.2 98.6,163.3 98.6,164.2 99.4,163.8 100.8,163 101.1,162.9 
			101.1,162.6 101.1,152.2 101.1,151.7 100.6,151.7 		"/>
		<path id="XMLID_1573_" d="M48.4,159.9v-4.7v-0.5h-0.5h-1.4H46v0.5v4.8c0,0.6-0.2,0.6-0.5,0.6H38v-5.4v-0.5h-0.5H34
			c-1.9,0-3.1,1.1-3.1,3v2.4c0,2.9,2.7,2.9,3.7,2.9h1.1H36h9.1C47.3,163.1,48.4,162,48.4,159.9z M35.5,160.6h-1.8
			c-0.3,0-0.5,0-0.5-0.6v-2.6c0-0.3,0.2-0.3,0.3-0.3h2V160.6z"/>
		<path id="XMLID_1576_" d="M35,152.3c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
			C35.6,150.9,35,151.5,35,152.3z"/>
		<path id="XMLID_1577_" d="M31.8,152.3c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
			C32.4,150.9,31.8,151.5,31.8,152.3z"/>
		<path id="XMLID_1578_" d="M47.2,164.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
			C48.5,164.8,47.9,164.2,47.2,164.2z"/>
		<path id="XMLID_1579_" d="M44,164.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
			C45.3,164.8,44.7,164.2,44,164.2z"/>
		<path id="XMLID_1580_" d="M172.4,151.7h-0.5v0.5v7.6c0,0.8-0.4,0.8-0.5,0.8h-6.8v-8.4v-0.5h-0.5h-1.4h-0.5v0.5v8.4h-5.8v-3.4
			c0-1.1-1-2.6-3.2-2.6c-2.3,0-3.1,1.6-3.1,2.6v3.4h-6.4v-8.4v-0.5h-0.5h-1.5h-0.5v0.5v8.4h-6.9c0-0.3,0-0.6,0-0.9v-0.2v-2.2
			c0-1.3-1-2.6-3-2.6h-1h-1c0-0.5,0-0.7,0.1-0.9s0.4-0.5,1-0.5h0.2h0.2h2.2h0.5v-0.5v-0.6v-0.5H133h-2.6c-1.9,0-3,1.1-3,3v2v0.5h0.5
			h3.5c0.7,0,0.7,0,0.7,0.8v2.5c0,0.1-0.2,0.2-0.3,0.2H130h-7.9v-5.4v-0.5h-0.5h-1.4h-0.5v0.5v5.4h-7.2v-5.4v-0.5H112h-3.5
			c-1.9,0-3.1,1.1-3.1,3v2.4c0,2.9,2.7,2.9,3.7,2.9h1.1h0.4h39.7c0.4,1,1.4,1.9,3,1.9c1.7,0,2.7-0.9,3-1.9h14.9h0.3
			c2.1,0,2.8-1.6,2.8-3v-7.8v-0.5h-0.5h-1.4V151.7z M110,160.6h-1.8c-0.3,0-0.5,0-0.5-0.6v-2.6c0-0.3,0.2-0.3,0.3-0.3h2V160.6z
			 M154,161.3c0,0.5,0,1-0.1,1.1c0,0-0.2,0.1-0.6,0.1c-0.3,0-0.5,0-0.6-0.1s-0.1-0.6-0.1-1.1c0-0.1,0-0.3,0-0.4v-2.4
			c0-0.1,0-0.3,0-0.4c0-0.5,0-1,0.1-1.1s0.3-0.1,0.6-0.1c0.5,0,0.6,0.1,0.6,0.1c0.1,0.1,0.1,0.6,0.1,1.1v0.3v2.5V161.3z"/>
		<path id="XMLID_1584_" d="M95.1,133.1h-0.5v0.5v7.6c0,0.7-0.4,0.7-0.7,0.7H87c0.2-0.2,0.5-0.4,0.7-0.6c0.4-0.4,0.9-0.7,1.3-1
			l0.2-0.2v-0.2c0-1.9-1.2-4-4-4c-2.7,0-4,2-4,4v0.2l0.2,0.2c0.5,0.5,1.2,1,2,1.7h-7.6c-0.3,0-0.7-0.1-0.7-0.7v-7.6v-0.5h-0.5h-0.7
			h-0.5v0.5v7.5c0,2.1,1.3,2.6,2.4,2.6h8.3c0.3,0,0.7-0.1,1-0.2c0.3,0.2,0.6,0.2,0.9,0.2h7.7c1.1,0,2.4-0.4,2.4-2.6v-7.5v-0.5h-0.5
			L95.1,133.1L95.1,133.1z M85.3,141.1l-0.4-0.3c-0.5-0.4-1.1-0.9-1.7-1.3c0.1-0.5,0.4-1.7,2.1-1.7s2,1.2,2.1,1.7
			C86.6,140.1,85.9,140.6,85.3,141.1z"/>
		<polygon id="XMLID_1587_" points="173.8,133.1 173.1,133.1 172.6,133.1 172.6,133.6 172.6,143.6 172.6,144.5 173.3,144.1 
			174,143.6 174.3,143.5 174.3,143.2 174.3,133.6 174.3,133.1 		"/>
		<polygon id="XMLID_1588_" points="101.6,133.1 101.1,133.1 101.1,133.6 101.1,143.6 101.1,144.5 101.8,144.1 102.6,143.6 
			102.8,143.5 102.8,143.2 102.8,133.6 102.8,133.1 102.3,133.1 		"/>
		<path id="XMLID_1589_" d="M33.2,143.7H35h0.2l0,0h0.3h18.6c0.3,1.1,1.4,1.8,2.9,1.8c1.4,0,2.5-0.7,2.9-1.8h6.9
			c1.1,0,2.4-0.4,2.4-2.6v-7.5v-0.5h-0.5H68h-0.5v0.5v7.6c0,0.7-0.4,0.7-0.7,0.7H60v-3.5c0-1.5-1.2-2.5-2.9-2.5
			c-1.8,0-2.9,1-2.9,2.5v3.5h-6.9v-5.4V136h-0.5h-0.7h-0.5v0.5v5.4h-8.7v-5.4V136h-0.5h-3c-1.2,0-2.4,0.9-2.4,2.5v2.6
			C30.8,142.6,31.8,143.7,33.2,143.7z M55.7,139c0-0.9,0.2-1.3,1.2-1.3s1.2,0.3,1.2,1.3v3.5c0,0.9-0.2,1.3-1.2,1.3s-1.2-0.3-1.2-1.3
			V139z M32.6,138.8c0-0.9,0.4-1,0.7-1H35v4.2h-1.7c-0.2,0-0.7,0-0.7-0.9V138.8z"/>
		<path id="XMLID_1593_" d="M35.6,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C34.6,134.7,35,135.1,35.6,135.1z"/>
		<path id="XMLID_1594_" d="M33.2,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C32.2,134.7,32.7,135.1,33.2,135.1z"/>
		<path id="XMLID_1595_" d="M137,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1s-1,0.4-1,1C136,134.7,136.5,135.1,137,135.1z"/>
		<path id="XMLID_1596_" d="M146.6,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C145.7,134.7,146.1,135.1,146.6,135.1z"
			/>
		<path id="XMLID_1597_" d="M109.9,143.7h1.8h0.2h0.3h7.4c0.3,1.1,1.4,1.8,2.9,1.8s2.5-0.7,2.9-1.8h28.9c0.3,1.1,1.4,1.8,2.9,1.8
			c1.4,0,2.5-0.7,2.9-1.8h5.6c1.1,0,2.4-0.4,2.4-2.5v-7.5v-0.5h-0.5h-0.7h-0.5v0.5v7.6c0,0.7-0.4,0.7-0.7,0.7H160v-3.5
			c0-1.5-1.2-2.5-2.9-2.5c-1.8,0-2.9,1-2.9,2.5v3.5h-6.5v-5.4V136h-0.5h-0.7H146v0.5v5.4h-6.2v-3.8c0-1.2-1.1-2.3-2.4-2.3
			c-0.2,0-0.5,0-0.7,0.1c-0.6,0.2-2.5,0.8-3.6,1.2v-3.6V133h-0.5h-0.7h-0.5v0.5v8.3h-5.8v-3.5c0-1.5-1.2-2.5-2.9-2.5
			c-1.8,0-2.9,1-2.9,2.5v3.5h-6.1v-5.4v-0.5h-0.5h-3c-1.2,0-2.4,0.9-2.4,2.5v2.6C107.4,142.6,108.5,143.7,109.9,143.7z M155.9,139
			c0-0.9,0.2-1.3,1.2-1.3s1.2,0.3,1.2,1.3v3.5c0,0.9-0.2,1.3-1.2,1.3s-1.2-0.3-1.2-1.3V139z M132.9,139.5c0-0.3,0.1-0.4,0.3-0.5
			c0.5-0.2,3.1-1.1,3.6-1.2c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1c0.2,0.1,0.2,0.4,0.2,0.7v3.5h-5v-2.5H132.9z M121.2,139
			c0-0.9,0.2-1.3,1.2-1.3s1.2,0.3,1.2,1.3v3.5c0,0.9-0.2,1.3-1.2,1.3s-1.2-0.3-1.2-1.3V139z M109.2,138.8c0-0.9,0.4-1,0.7-1h1.7v4.2
			h-1.7c-0.2,0-0.7,0-0.7-0.9V138.8z"/>
		<path id="XMLID_1603_" d="M44.5,145.5c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1S44.5,145,44.5,145.5z"/>
		<path id="XMLID_1604_" d="M42.1,145.5c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1C42.6,144.5,42.1,145,42.1,145.5z"/>
		<path id="XMLID_1605_" d="M108.8,152.3c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
			C109.4,150.9,108.8,151.5,108.8,152.3z"/>
		<path id="XMLID_1606_" d="M106.9,153.6c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4
			C105.6,153,106.2,153.6,106.9,153.6z"/>
		<path id="XMLID_1607_" d="M81.9,152.3c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4
			C81.3,153.6,81.9,153,81.9,152.3z"/>
		<path id="XMLID_1608_" d="M120.7,164.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4S121.5,164.2,120.7,164.2z"
			/>
		<path id="XMLID_1609_" d="M117.6,164.2c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4
			C118.9,164.8,118.3,164.2,117.6,164.2z"/>
		<path id="XMLID_1610_" d="M111.7,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C110.7,134.7,111.2,135.1,111.7,135.1z"
			/>
		<path id="XMLID_1611_" d="M109.4,135.1c0.5,0,1-0.4,1-1c0-0.5-0.4-1-1-1c-0.5,0-1,0.4-1,1C108.4,134.7,108.8,135.1,109.4,135.1z"
			/>
	</g>
</g>
</svg>
`;

export const es = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 209.5 167" style="enable-background:new 0 0 209.5 167;" xml:space="preserve">
<g>
	<g>
		<path d="M73.4,20.1c1.3-1.6,2.1-3.4,2.1-5.2C75.4,8.9,67.2,4,54.5,0.1L54,0v0.5v1.7l0.3,0.1C60.6,3.8,65.6,5.6,69,7.6
			c3.4,2,5.2,4.1,5.2,6.2c0,1.5-0.8,3-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L54,10.2v0.5v1.7l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4
			c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,20.4v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
			c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2
			c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,30.6v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3.1-21.2,5.6-35.9,7.2V47
			c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4
			c-2.4-1-5.1-1.9-8-2.8L54,40.8v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8
			c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-2-8-2.8L54,51v0.5
			v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9
			c1.4,0.6,2.7,1.2,3.8,1.9c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8L54,61.2v0.5v1.7l0.3,0.1
			c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.9
			c0.7,0.4,1.3,0.8,1.9,1.2c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3C39.2,85.8,52,82.8,61,79
			c5.3-2.2,9.3-4.6,11.7-7.2c0.9,1.1,1.3,2.2,1.3,3.2c0.1,3.6-4.9,7.3-13.8,10.4C51.5,88.5,39,91,24.2,92.7V98
			C39.2,96,52,93,61,89.2c9-3.7,14.4-8.1,14.4-13.1c0-1.8-0.7-3.5-2.1-5.1c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5
			c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.7,2.1-3.4,2.1-5.2
			c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2C75.4,23.4,74.7,21.7,73.4,20.1z M66.7,25.3c2.6-1.4,4.6-3,6.1-4.5
			c0.9,1.1,1.3,2.1,1.3,3.2c0,1.5-0.9,3-2.5,4.5C70.3,27.4,68.6,26.3,66.7,25.3z M74.1,64.8c0,1.5-0.9,3.1-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,62.7,74.1,63.7,74.1,64.8z M74.1,54.6c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,52.5,74.1,53.5,74.1,54.6z M74.1,44.4c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,42.2,74.1,43.3,74.1,44.4z M74.1,34.2c0,1.5-0.9,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-3,6.1-4.5C73.7,32,74.1,33.1,74.1,34.2z"/>
	</g>
	<g>
		<path d="M39.3,105.9h6.1v15.9h-4v-12.7h0l-4.8,12.7h-3.5l-4.8-12.7h0v12.7h-4v-15.9h6.1l4.5,11.4L39.3,105.9z"/>
		<path d="M51.1,105.9h10.3c4.3,0,5.4,2.7,5.4,4.8c0,3.4-1.7,5.4-5.1,5.4h-6.4v5.7h-4.2V105.9z M55.2,113.2h4.5
			c1.3,0,2.8-0.1,2.8-2.2c0-2.1-1.2-2.2-2.4-2.2h-4.8V113.2z"/>
		<path d="M71.2,105.9h4.2v15.9h-4.2V105.9z"/>
		<path d="M9.9,105.5c8.6,0,9.9,5.1,9.9,8.4c0,3.3-1.3,8.4-9.9,8.4S0,117.2,0,113.9S1.3,105.5,9.9,105.5z M9.9,119.2
			c3.9,0,5.3-2.9,5.3-5.4c0-2.5-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S6,119.2,9.9,119.2z"/>
	</g>
	<g>
		<path d="M23.8,134.5c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C25,138.6,23.8,136.7,23.8,134.5z M31.2,134.5
			c0-1.7-1-2.9-2.9-2.9c-1.8,0-2.9,1.2-2.9,2.9s1,2.9,2.9,2.9C30.2,137.4,31.2,136.2,31.2,134.5z"/>
		<path d="M35.4,130.6h4.9c2,0,2.6,0.9,2.6,2.1c0,1-0.6,1.6-1.3,1.9v0c0.9,0.2,1.1,1,1.1,2.1c0,0.4,0,1.2,0.3,1.7h-1.8
			c-0.1-0.2-0.2-0.6-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9H37v3.1h-1.6C35.4,138.4,35.4,130.6,35.4,130.6z M39.8,134.1
			c0.9,0,1.4-0.3,1.4-1.1c0-0.8-0.5-1.1-1.4-1.1H37v2.3L39.8,134.1L39.8,134.1z"/>
		<path d="M53.8,138.4h-1l-0.2-1c-0.6,0.8-1.7,1.2-3,1.2c-3.3,0-4.6-1.9-4.6-4.1s1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4,2.7h-1.8
			c-0.3-1.1-1.3-1.5-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c0,2.1,1.3,2.8,2.7,2.8c1.7,0,2.4-0.8,2.7-2.1H50v-1.2h3.8L53.8,138.4L53.8,138.4z
			"/>
		<path d="M59.3,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L59.3,130.6z M61.6,135.2l-1.3-3.3l-1.3,3.3H61.6z"/>
		<path d="M66.7,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8h-1.6V130.6z"/>
		<path d="M77.7,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M81.4,137.2l5.4-5.4h-5v-1.2h7.1v1.2l-5.3,5.3H89v1.3h-7.5V137.2z"/>
		<path d="M94.1,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L94.1,130.6z M96.4,135.2l-1.3-3.3l-1.3,3.3H96.4z"/>
		<path d="M107.6,133.5c-0.2-1.2-1.1-1.9-2.5-1.9c-1.5,0-2.6,1-2.6,3c0,1.6,1.1,2.8,2.7,2.8c1.7,0,2.3-1.1,2.4-2h1.8
			c-0.2,1.6-1.2,3.2-4.4,3.2c-2.8,0-4.2-1.9-4.2-4.1s1.3-4.1,4.3-4.1c2.7,0,4.1,1.4,4.2,3.1H107.6z"/>
		<path d="M111.8,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M115.9,134.5c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C117.2,138.6,115.9,136.7,115.9,134.5z M123.4,134.5
			c0-1.7-1.1-2.9-2.9-2.9c-1.8,0-2.9,1.2-2.9,2.9s1.1,2.9,2.9,2.9C122.3,137.4,123.4,136.2,123.4,134.5z M120.6,128.6h1.8l-1.5,1.6
			h-1.3L120.6,128.6z"/>
		<path d="M127.6,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8h-1.6C127.6,138.4,127.6,130.6,127.6,130.6z"/>
		<path d="M143.7,130.6h2.4l2.7,6l2.7-6h2.3v7.8h-1.5l0.1-6.4h0l-2.9,6.4H148l-2.9-6.4h0l0.1,6.4h-1.5L143.7,130.6L143.7,130.6z"/>
		<path d="M156.6,130.6h1.6v4.4c0,1.1,0,2.4,2.2,2.4c2.1,0,2.2-1.3,2.2-2.4v-4.4h1.6v4.4c0,1.3,0,3.6-3.8,3.6
			c-3.8,0-3.8-2.3-3.8-3.6V130.6z"/>
		<path d="M167.1,130.6h1.9l4.6,5.8h0v-5.8h1.6v7.8h-1.8l-4.7-5.8h0v5.8h-1.6V130.6z"/>
		<path d="M178,130.6h4.2c3.1,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8H178L178,130.6L178,130.6z M179.7,137.1h2.3
			c1.2,0,2.4-0.4,2.4-2.7c0-2.5-1.6-2.7-2.5-2.7h-2.2L179.7,137.1L179.7,137.1z"/>
		<path d="M188.6,130.6h1.6v7.8h-1.6V130.6z"/>
		<path d="M195.7,130.6h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L195.7,130.6z M198,135.2l-1.3-3.3l-1.4,3.3H198z"/>
		<path d="M203.1,130.6h1.6v6.5h4.7v1.3h-6.4V130.6z"/>
		<path d="M24.2,144.8h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6L24.2,144.8L24.2,144.8z M25.8,151.3h2.3
			c1.2,0,2.4-0.4,2.4-2.7c0-2.5-1.6-2.7-2.5-2.7h-2.2V151.3z"/>
		<path d="M34.7,144.8h6.8v1.2h-5.1v2h4.9v1.2h-4.9v2.2h5.2v1.2h-6.8V144.8z"/>
		<path d="M49.2,144.8h1.6v6.5h4.7v1.3h-6.4V144.8z"/>
		<path d="M60.7,144.8h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.7l-0.8,1.9h-1.8L60.7,144.8z M63,149.4l-1.3-3.3l-1.3,3.3H63z"/>
		<path d="M73.3,144.8H79c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.8,2.6h-3.3v2.6h-2.6C73.3,152.5,73.3,144.8,73.3,144.8z
			 M75.9,148.4h2.3c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
		<path d="M84,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0,1-0.6,1.6-1.2,1.8v0c0.5,0.2,0.9,0.6,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
			c-0.1-0.3-0.2-0.8-0.2-1.1c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6H84V144.8z M86.6,148.2h2.5c0.6,0,1-0.3,1-0.9c0-0.5-0.3-0.8-1-0.8h-2.5
			V148.2z"/>
		<path d="M99.9,144.6c2.8,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1s-5.1-0.9-5.1-4.1C94.8,145.4,97.2,144.6,99.9,144.6z
			 M99.9,151.1c1.4,0,2.3-0.8,2.3-2.4c0-1.6-0.9-2.4-2.3-2.4c-1.4,0-2.3,0.8-2.3,2.4C97.6,150.3,98.5,151.1,99.9,151.1z"/>
		<path d="M107.3,144.8h5.8c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.8,2.6h-3.3v2.6h-2.6C107.3,152.5,107.3,144.8,107.3,144.8z
			 M109.9,148.4h2.3c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
		<path d="M118,144.8h2.6v7.8H118V144.8z"/>
		<path d="M123.3,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
		<path d="M133.6,144.8h5.6c2.4,0,3.7,1.3,3.7,3.9c0,3.1-1.8,3.9-4,3.9h-5.3V144.8z M136.2,150.8h2.2c1.5,0,1.9-1.1,1.9-2.2
			c0-1.4-0.7-2.1-2.1-2.1h-2V150.8z"/>
		<path d="M147.3,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L147.3,144.8z M148.9,146.8L148.9,146.8l-1.1,2.9h2.2
			L148.9,146.8z"/>
		<path d="M155.7,144.8h5.6c2.4,0,3.7,1.3,3.7,3.9c0,3.1-1.8,3.9-4,3.9h-5.3V144.8z M158.3,150.8h2.2c1.5,0,1.9-1.1,1.9-2.2
			c0-1.4-0.7-2.1-2.1-2.1h-2V150.8z"/>
		<path d="M24.2,159h2.6v7.8h-2.6V159z"/>
		<path d="M29.7,159h3l3.6,4.9h0V159h2.5v7.8H36l-3.7-4.9h0v4.9h-2.5V159z"/>
		<path d="M41,159h8.3v1.7h-2.8v6h-2.6v-6H41L41,159L41,159z"/>
		<path d="M51.5,159h7.8v1.7H54v1.4h4.9v1.6H54v1.4h5.2v1.7h-7.8V159z"/>
		<path d="M61.9,159h2.6v6h4.7v1.8h-7.3C61.9,166.8,61.9,159,61.9,159z"/>
		<path d="M71.5,159h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V159z"/>
		<path d="M91.2,163.8c-0.3,1.7-1.2,3.2-4.9,3.2c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.6,4.9,3.2h-2.9
			c-0.2-0.6-0.5-1.5-2-1.5c-1.6,0-2.2,1.1-2.2,2.4s0.5,2.4,2.2,2.4c1.2,0,1.9-0.6,2-1.5L91.2,163.8L91.2,163.8z"/>
		<path d="M92.9,159h8.3v1.7h-2.8v6h-2.6v-6h-2.8L92.9,159L92.9,159z"/>
		<path d="M103.3,159h2.6v4.6c0,1.3,0.6,1.7,1.8,1.7s1.8-0.4,1.8-1.7V159h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3L103.3,159
			L103.3,159z"/>
		<path d="M117,159h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L117,159z M118.6,160.9L118.6,160.9l-1.1,2.9h2.2L118.6,160.9z"/>
		<path d="M125.6,159h2.6v6h4.7v1.8h-7.3V159z"/>
	</g>
</g>
</svg>
`;

export const ru = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 227.5 167" style="enable-background:new 0 0 227.5 167;" xml:space="preserve">
<g id="XMLID_449_">
	<g id="XMLID_816_">
		<path id="XMLID_825_" d="M0,105.7h4.3l3.1,11.6h0.1l3.2-11.6H15l3.2,11.6h0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3h0l-3.3,11.3H5
			L0,105.7z"/>
		<path id="XMLID_823_" d="M30,105.7h4.3v15.9H30V105.7z"/>
		<path id="XMLID_820_" d="M40.7,105.7h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4H45v5.7h-4.3V105.7z M45,113h4.6
			c1.3,0,2.9-0.1,2.9-2.2c0-2.1-1.3-2.2-2.5-2.2h-5V113z"/>
		<path id="XMLID_817_" d="M71.4,105.2c8.5,0,9.9,5.1,9.9,8.4c0,3.3-1.3,8.4-9.9,8.4c-8.5,0-9.9-5.1-9.9-8.4
			C61.5,110.3,62.8,105.2,71.4,105.2z M71.4,119c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4
			C66.1,116.1,67.4,119,71.4,119z"/>
	</g>
	<g id="XMLID_808_">
		<path id="XMLID_809_" d="M79.1,20.1c1.3-1.6,2-3.4,2.1-5.1C81.1,8.9,73,4,60.2,0.1L59.8,0v0.5v1.7L60,2.2
			c6.2,1.5,11.2,3.4,14.6,5.3c3.4,2,5.2,4.1,5.2,6.2c0,1.5-0.8,3-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8l-0.5-0.1v0.5v1.7l0.3,0.1
			c6.2,1.6,11.2,3.4,14.6,5.3c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v0.5v1.7l0.3,0.1
			c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15-2,27.8-5,36.8-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.9
			c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1V31v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
			c-8.8,3-21.2,5.5-35.9,7.2v5.3c15-2,27.8-5,36.8-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2
			c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V57
			c15-2,27.8-5,36.8-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4
			c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v0.5V53l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15-2,27.8-5,36.8-8.7
			c1.5-0.6,2.9-1.2,4.1-1.9c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2c-2,1.5-4.8,3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
			v0.5v1.7l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15-2,27.8-5,36.8-8.7c1.5-0.6,2.9-1.2,4.1-1.9
			c1.4,0.6,2.7,1.2,3.8,1.8c0.7,0.4,1.3,0.8,1.9,1.2c-2.3,1.8-5.9,3.6-10.4,5.2c-8.8,3.1-21.3,5.6-36.1,7.3v5.3
			c15-2,27.8-5,36.8-8.8c5.3-2.2,9.3-4.6,11.7-7.2c0.9,1.1,1.3,2.1,1.3,3.2c0.1,3.6-4.9,7.3-13.7,10.3c-8.8,3.1-21.3,5.6-36.1,7.3
			v5.3c15-2,27.8-5,36.8-8.8c9-3.7,14.3-8.1,14.4-13c0-1.8-0.7-3.4-2.1-5c1.3-1.6,2.1-3.3,2.1-5.2c0-1.8-0.7-3.5-2.1-5
			c1.3-1.6,2.1-3.4,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.3,2.1-5.2c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.2
			c0-1.8-0.7-3.5-2.1-5c1.3-1.6,2.1-3.4,2.1-5.1C81.1,23.3,80.4,21.6,79.1,20.1z M72.4,25.3c2.6-1.4,4.6-2.9,6.1-4.5
			c0.9,1.1,1.3,2.1,1.3,3.2c0,1.5-0.8,3-2.5,4.5C76,27.4,74.3,26.3,72.4,25.3z M79.8,64.7c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-2.9,6.1-4.5C79.4,62.5,79.9,63.6,79.8,64.7z M79.8,54.5c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-2.9,6.1-4.5C79.4,52.3,79.9,53.4,79.8,54.5z M79.8,44.3c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-2.9,6.1-4.5C79.4,42.2,79.9,43.2,79.8,44.3z M79.8,34.1c0,1.5-0.8,3-2.5,4.5
			c-1.3-1.1-3-2.2-4.9-3.2c2.6-1.4,4.6-2.9,6.1-4.5C79.4,32,79.9,33.1,79.8,34.1z"/>
	</g>
	<g id="XMLID_322_">
		<path id="XMLID_482_" d="M34.6,138.5c1.4,0,2.9-0.8,2.9-2.6c0-0.9-0.4-1.6-1.1-2c0.5-0.4,0.8-0.9,0.8-1.6c0-1.4-0.9-2.3-2.5-2.3
			h-4.4H30v0.3v7.8v0.3h0.3L34.6,138.5L34.6,138.5z M31.4,131.5h3.1c1,0,1.2,0.4,1.2,0.9c0,0.6-0.2,1.1-1.5,1.1h-2.9
			C31.4,133.5,31.4,131.5,31.4,131.5z M31.4,134.9h3.3c0.9,0,1.3,0.3,1.3,1.1c0,0.3,0,1.2-1.5,1.2h-3.1L31.4,134.9L31.4,134.9z"/>
		<path id="XMLID_827_" d="M43.6,138.7c2.2,0,4-1.4,4.2-3.4l0-0.4h-0.4h-0.8h-0.3l0,0.3c-0.3,1.4-1.2,2.1-2.7,2.1
			c-2.7,0-2.9-2.1-2.9-3c0-1.9,1-3,2.9-3c0.7,0,2.4,0.2,2.7,1.8l0,0.3h0.3h0.8h0.4l0-0.4c-0.3-2.3-2.4-3.1-4.1-3.1
			c-3.1,0-4.5,2.3-4.5,4.4C39.1,137,40.9,138.7,43.6,138.7z"/>
		<polygon id="XMLID_829_" points="57,138.5 57,138.2 57,137.4 57,137.1 56.7,137.1 51.5,137.1 51.5,134.9 56.4,134.9 56.7,134.9 
			56.7,134.5 56.7,133.8 56.7,133.4 56.4,133.4 51.5,133.4 51.5,131.5 56.6,131.5 56.9,131.5 56.9,131.1 56.9,130.4 56.9,130.1 
			56.6,130.1 50.4,130.1 50,130.1 50,130.4 50,138.2 50,138.5 50.4,138.5 56.7,138.5 		"/>
		<path id="XMLID_832_" d="M60.2,138.5h0.3v-0.3v-5.4l2.8,5.6l0.1,0.2h0.2h0.7h0.2l0.1-0.2l2.8-5.6v5.4v0.3h0.3h0.8h0.3v-0.3v-7.8
			v-0.3h-0.3h-1.1h-0.2l-0.1,0.2l-3.2,6.2l-3.2-6.2l-0.1-0.2h-0.2h-1.2H59v0.3v7.8v0.3h0.3L60.2,138.5L60.2,138.5z"/>
		<path id="XMLID_835_" d="M72.6,138.5h0.2l0.1-0.1l4.9-6v5.8v0.3h0.3h0.8h0.3v-0.3v-7.8v-0.3h-0.3H78h-0.2l-0.1,0.1l-4.9,6v-5.8
			v-0.3h-0.3h-0.8h-0.3v0.3v7.8v0.3h0.3L72.6,138.5L72.6,138.5z"/>
		<path id="XMLID_838_" d="M82.9,138.5h0.3v-0.3v-2.9h2.7c1.9,0,3-0.9,3-2.6c0-1.9-1.4-2.6-2.7-2.6h-4.2h-0.3v0.3v7.8v0.3h0.3
			L82.9,138.5L82.9,138.5z M83.2,131.5h2.9c0.9,0,1.2,0.3,1.2,1.2c0,0.7-0.2,1.2-1.4,1.2h-2.8L83.2,131.5L83.2,131.5z"/>
		<polygon id="XMLID_840_" points="92,138.2 92,134.8 97,134.8 97,138.2 97,138.5 97.3,138.5 98.1,138.5 98.5,138.5 98.5,138.2 
			98.5,130.4 98.5,130.1 98.1,130.1 97.3,130.1 97,130.1 97,130.4 97,133.4 92,133.4 92,130.4 92,130.1 91.7,130.1 90.9,130.1 
			90.6,130.1 90.6,130.4 90.6,138.2 90.6,138.5 90.9,138.5 91.7,138.5 92,138.5 		"/>
		<path id="XMLID_843_" d="M100.9,138.5h0.2l0.1-0.2l1-2.2h3.7l0.9,2.2l0.1,0.2h0.2h0.9h0.5l-0.2-0.5l-3.5-7.8l-0.1-0.2h-0.2h-0.9
			h-0.2l-0.1,0.2l-3.5,7.8l-0.2,0.5h0.5L100.9,138.5L100.9,138.5z M104,132l1.2,2.7h-2.4L104,132z"/>
		<path id="XMLID_847_" d="M111,138.5h0.2l0.1-0.1c0.2-0.2,0.3-0.6,0.3-1.8c0-1.2,0.4-1.5,1.6-1.5h2.8v3.1v0.3h0.3h0.8h0.3v-0.3
			v-7.8v-0.3H117h-4.2c-1.8,0-2.8,0.9-2.8,2.4c0,0.7,0.3,1.4,1,1.9c-0.7,0.4-0.8,1.3-0.8,2.2c0,0.9-0.1,1.3-0.2,1.4l-0.6,0.6l0.8,0
			L111,138.5L111,138.5z M112.7,131.5h3.2v2.2h-3.1c-1.2,0-1.3-0.6-1.3-1.2C111.4,132,111.6,131.5,112.7,131.5z"/>
		<path id="XMLID_850_" d="M130.9,138.7c2.3,0,4.6-1.4,4.6-4.4s-2.3-4.4-4.6-4.4c-2.3,0-4.6,1.4-4.6,4.4
			C126.4,137.3,128.6,138.7,130.9,138.7z M130.9,131.3c1.5,0,3,0.8,3,3c0,2.2-1.6,3-3,3c-1.5,0-3-0.8-3-3
			C127.9,132.1,129.4,131.3,130.9,131.3z"/>
		<path id="XMLID_853_" d="M138.5,138.5h0.3v-0.3v-2.9h2.7c1.9,0,3-0.9,3-2.6c0-1.9-1.4-2.6-2.7-2.6h-4.2h-0.3v0.3v7.8v0.3h0.3
			L138.5,138.5L138.5,138.5z M138.8,131.5h2.9c0.9,0,1.2,0.3,1.2,1.2c0,0.7-0.2,1.2-1.4,1.2h-2.8L138.8,131.5L138.8,131.5z"/>
		<polygon id="XMLID_855_" points="148,138.2 148,131.5 152.6,131.5 152.9,131.5 152.9,131.1 152.9,130.4 152.9,130.1 152.6,130.1 
			146.8,130.1 146.5,130.1 146.5,130.4 146.5,138.2 146.5,138.5 146.8,138.5 147.6,138.5 148,138.5 		"/>
		<path id="XMLID_858_" d="M151.6,138.5h0.5h0.9h0.2l0.1-0.2l1-2.2h3.7l0.9,2.2l0.1,0.2h0.2h0.9h0.5l-0.2-0.5l-3.5-7.8l-0.1-0.2
			h-0.2h-0.9h-0.2l-0.1,0.2l-3.5,7.8L151.6,138.5z M156.1,132l1.2,2.7h-2.4L156.1,132z"/>
		<polygon id="XMLID_861_" points="168.5,130.1 168.2,130.1 168.2,130.4 168.2,133.4 163.2,133.4 163.2,130.4 163.2,130.1 
			162.9,130.1 162.1,130.1 161.7,130.1 161.7,130.4 161.7,138.2 161.7,138.5 162.1,138.5 162.9,138.5 163.2,138.5 163.2,138.2 
			163.2,134.8 168.2,134.8 168.2,138.2 168.2,138.5 168.5,138.5 169.3,138.5 169.6,138.5 169.6,138.2 169.6,130.4 169.6,130.1 
			169.3,130.1 		"/>
		<polygon id="XMLID_862_" points="178.6,130.1 178.5,130.1 178.4,130.2 173.5,136.2 173.5,130.4 173.5,130.1 173.2,130.1 
			172.4,130.1 172.1,130.1 172.1,130.4 172.1,138.2 172.1,138.5 172.4,138.5 173.3,138.5 173.5,138.5 173.6,138.4 178.5,132.4 
			178.5,138.2 178.5,138.5 178.8,138.5 179.6,138.5 179.9,138.5 179.9,138.2 179.9,130.4 179.9,130.1 179.6,130.1 		"/>
		<path id="XMLID_865_" d="M185.8,137.3c-1.4,0-2-0.5-2.1-1.7l0-0.3h-0.3h-0.8h-0.3v0.3c0,1.9,1.2,3,3.4,3c2.2,0,3.4-1,3.4-2.7
			c0-0.9-0.4-1.6-1.1-2c0.5-0.4,0.8-1,0.8-1.6c0-1.5-1.2-2.5-3-2.5c-2.4,0-3.3,1.5-3.3,2.9l0,0.3h0.3h0.8h0.3v-0.3
			c0-1.1,0.6-1.6,1.9-1.6c0.7,0,1.6,0.2,1.6,1.1c0,0.6-0.3,1-1.6,1h-0.5h-0.3v0.3v0.7v0.3h0.3h0.7c1.3,0,1.8,0.4,1.8,1.3
			C187.6,136.9,186.9,137.3,185.8,137.3z"/>
		<path id="XMLID_868_" d="M194.8,130.3l-0.1-0.2h-0.2h-0.9h-0.2l-0.1,0.2l-3.5,7.8l-0.2,0.5h0.5h0.9h0.2l0.1-0.2l1-2.2h3.7l0.9,2.2
			l0.1,0.2h0.2h0.9h0.5l-0.2-0.5L194.8,130.3z M194,132l1.2,2.7h-2.4L194,132z"/>
		<path id="XMLID_872_" d="M216.5,130.1h-0.1l-0.1,0.1l-4.9,6v-5.8v-0.3H211h-0.8h-0.3v0.3v7.8v0.3h0.3h0.9h0.1l0.1-0.1l4.9-6v5.8
			v0.3h0.3h0.8h0.3v-0.3v-7.8v-0.3h-0.3L216.5,130.1L216.5,130.1z"/>
		<path id="XMLID_875_" d="M227.2,130.1H223c-1.8,0-2.9,0.9-2.9,2.4c0,0.7,0.3,1.4,1,1.9c-0.7,0.4-0.8,1.3-0.9,2.2
			c-0.1,1.2-0.2,1.4-0.2,1.4l-0.6,0.6l0.8,0h0.9h0.1l0.1-0.1c0.2-0.2,0.3-0.6,0.3-1.8c0-1.2,0.4-1.5,1.6-1.5h2.8v3.1v0.3h0.3h0.8
			h0.3v-0.3v-7.8v-0.3L227.2,130.1L227.2,130.1z M226.1,133.7H223c-1.2,0-1.4-0.6-1.4-1.2c0-0.5,0.1-1,1.3-1h3.2L226.1,133.7
			L226.1,133.7z"/>
		<polygon id="XMLID_876_" points="207.5,137.1 207.5,130.4 207.5,130.1 207.1,130.1 206.3,130.1 206,130.1 206,130.4 206,137.1 
			201,137.1 201,130.4 201,130.1 200.7,130.1 199.9,130.1 199.6,130.1 199.6,130.4 199.6,138.2 199.6,138.5 199.9,138.5 
			206.6,138.5 206.6,139.9 206.6,140.3 206.9,140.3 207.7,140.3 208.1,140.3 208.1,139.9 208.1,137.4 208.1,137.1 207.7,137.1 		"/>
		<path id="XMLID_879_" d="M36.4,163H36l-0.1,0.3c-0.1,0.6-0.5,1.6-2,1.6c-1.4,0-2.3-0.9-2.3-2.3c0-1.7,0.8-2.6,2.2-2.6
			c1.2,0,2,0.6,2.1,1.5l0,0.4h0.3h1.8h0.5l0-0.4c-0.3-2.6-2.7-3.5-4.7-3.5c-3.5,0-4.8,2.3-4.8,4.5c0,3.1,2.3,4.5,4.6,4.5
			c3.8,0,4.7-2.2,4.8-3.5l0.1-0.5h-0.5L36.4,163L36.4,163z"/>
		<path id="XMLID_882_" d="M45.2,158c-3.1,0-5,1.7-5,4.5c0,2.8,1.9,4.5,5,4.5s5-1.7,5-4.5S48.4,158,45.2,158z M45.2,165
			c-1.5,0-2.5-0.9-2.5-2.5s0.9-2.5,2.5-2.5c1.5,0,2.5,0.9,2.5,2.5C47.7,164,46.8,165,45.2,165z"/>
		<path id="XMLID_885_" d="M69.6,163h-0.3l-0.1,0.3c-0.1,0.6-0.5,1.6-2,1.6c-1.4,0-2.3-0.9-2.3-2.3c0-1.7,0.8-2.6,2.2-2.6
			c1.2,0,2,0.6,2.1,1.5l0.1,0.4h0.3h1.8h0.4l-0.1-0.4c-0.3-2.6-2.7-3.5-4.7-3.5c-3.5,0-4.8,2.3-4.8,4.5c0,3.1,2.3,4.5,4.6,4.5
			c3.8,0,4.7-2.2,4.8-3.5l0.1-0.5h-0.5L69.6,163L69.6,163z"/>
		<polygon id="XMLID_886_" points="73.5,158.2 73.1,158.2 73.1,158.6 73.1,159.8 73.1,160.2 73.5,160.2 76,160.2 76,166.4 76,166.8 
			76.4,166.8 78,166.8 78.4,166.8 78.4,166.4 78.4,160.2 80.9,160.2 81.3,160.2 81.3,159.8 81.3,158.6 81.3,158.2 80.9,158.2 		"/>
		<path id="XMLID_889_" d="M90.5,162.1c0.3-0.3,0.6-0.8,0.6-1.6c0-0.9-0.3-2.4-2.6-2.4h-5.1H83v0.4v7.8v0.4h0.4h5.1
			c2.4,0,3.1-1.7,3.1-2.7C91.5,163.4,91.3,162.6,90.5,162.1z M85.4,160.2h2.4c0.9,0,0.9,0.3,0.9,0.5c0,0.4-0.2,0.6-0.7,0.6h-2.6
			V160.2z M87.9,164.8h-2.5v-1.4h2.8c0.4,0,0.8,0.1,0.8,0.7C89,164.5,88.8,164.8,87.9,164.8z"/>
		<polygon id="XMLID_892_" points="96.1,164.8 96.1,163.4 100.6,163.4 101,163.4 101,163 101,161.8 101,161.4 100.6,161.4 
			96.1,161.4 96.1,160.2 100.8,160.2 101.2,160.2 101.2,159.8 101.2,158.6 101.2,158.2 100.8,158.2 94.1,158.2 93.7,158.2 
			93.7,158.6 93.7,166.4 93.7,166.8 94.1,166.8 100.9,166.8 101.3,166.8 101.3,166.4 101.3,165.1 101.3,164.8 100.9,164.8 		"/>
		<polygon id="XMLID_893_" points="110.2,158.2 109.8,158.2 109.8,158.6 109.8,161.2 105.8,161.2 105.8,158.6 105.8,158.2 
			105.4,158.2 103.8,158.2 103.4,158.2 103.4,158.6 103.4,166.4 103.4,166.8 103.8,166.8 105.4,166.8 105.8,166.8 105.8,166.4 
			105.8,163.2 109.8,163.2 109.8,166.4 109.8,166.8 110.2,166.8 111.9,166.8 112.3,166.8 112.3,166.4 112.3,158.6 112.3,158.2 
			111.9,158.2 		"/>
		<polygon id="XMLID_894_" points="121.6,158.2 121.2,158.2 121.2,158.6 121.2,161.2 117.2,161.2 117.2,158.6 117.2,158.2 
			116.7,158.2 115.1,158.2 114.7,158.2 114.7,158.6 114.7,166.4 114.7,166.8 115.1,166.8 116.7,166.8 117.2,166.8 117.2,166.4 
			117.2,163.2 121.2,163.2 121.2,166.4 121.2,166.8 121.6,166.8 123.2,166.8 123.6,166.8 123.6,166.4 123.6,158.6 123.6,158.2 
			123.2,158.2 		"/>
		<path id="XMLID_897_" d="M130.6,158c-3.1,0-5,1.7-5,4.5c0,2.8,1.9,4.5,5,4.5c3.1,0,5-1.7,5-4.5S133.7,158,130.6,158z M130.6,165
			c-1.6,0-2.5-0.9-2.5-2.5s0.9-2.5,2.5-2.5s2.5,0.9,2.5,2.5C133,164,132.1,165,130.6,165z"/>
		<path id="XMLID_900_" d="M144.3,163H144l-0.1,0.3c-0.1,0.6-0.5,1.6-2,1.6c-1.4,0-2.3-0.9-2.3-2.3c0-1.7,0.8-2.6,2.2-2.6
			c1.2,0,2,0.6,2.1,1.5l0.1,0.4h0.4h1.8h0.4l-0.1-0.4c-0.3-2.6-2.7-3.5-4.6-3.5c-3.5,0-4.8,2.3-4.8,4.5c0,3.1,2.3,4.5,4.6,4.5
			c3.8,0,4.6-2.2,4.9-3.5l0.1-0.5h-0.5L144.3,163L144.3,163z"/>
		<polygon id="XMLID_901_" points="148.2,158.2 147.9,158.2 147.9,158.6 147.9,159.8 147.9,160.2 148.2,160.2 150.8,160.2 
			150.8,166.4 150.8,166.8 151.2,166.8 152.8,166.8 153.2,166.8 153.2,166.4 153.2,160.2 155.7,160.2 156.1,160.2 156.1,159.8 
			156.1,158.6 156.1,158.2 155.7,158.2 		"/>
		<polygon id="XMLID_902_" points="164.4,158.2 164.2,158.2 164.1,158.4 160.1,163.3 160.1,158.6 160.1,158.2 159.7,158.2 
			158.2,158.2 157.8,158.2 157.8,158.6 157.8,166.4 157.8,166.8 158.2,166.8 160,166.8 160.2,166.8 160.3,166.6 164.3,161.7 
			164.3,166.4 164.3,166.8 164.7,166.8 166.2,166.8 166.7,166.8 166.7,166.4 166.7,158.6 166.7,158.2 166.2,158.2 		"/>
		<path id="XMLID_905_" d="M57.3,161.4h-2.6v-1.1h4.4h0.4v-0.4v-1.2v-0.4h-0.4h-6.4h-0.4v0.4v7.8v0.4h0.4h5.1c2.4,0,3.1-1.7,3.1-2.7
			C60.8,162.8,59.8,161.4,57.3,161.4z M57.2,164.8h-2.5v-1.4h2.8c0.4,0,0.8,0.1,0.8,0.7C58.2,164.5,58.1,164.8,57.2,164.8z"/>
		<polygon id="XMLID_908_" points="36.5,144.1 36.3,144.1 36.2,144.2 32.3,149.2 32.3,144.5 32.3,144.1 31.9,144.1 30.3,144.1 
			29.9,144.1 29.9,144.5 29.9,152.3 29.9,152.7 30.3,152.7 32.2,152.7 32.4,152.7 32.5,152.5 36.4,147.6 36.4,152.3 36.4,152.7 
			36.8,152.7 38.4,152.7 38.8,152.7 38.8,152.3 38.8,144.5 38.8,144.1 38.4,144.1 		"/>
		<polygon id="XMLID_909_" points="47.7,144.5 47.7,147.1 43.7,147.1 43.7,144.5 43.7,144.1 43.3,144.1 41.7,144.1 41.2,144.1 
			41.2,144.5 41.2,152.3 41.2,152.7 41.7,152.7 43.3,152.7 43.7,152.7 43.7,152.3 43.7,149.2 47.7,149.2 47.7,152.3 47.7,152.7 
			48.1,152.7 49.7,152.7 50.2,152.7 50.2,152.3 50.2,144.5 50.2,144.1 49.7,144.1 48.1,144.1 47.7,144.1 		"/>
		<polygon id="XMLID_910_" points="54.2,152.3 54.2,152.7 54.7,152.7 56.3,152.7 56.7,152.7 56.7,152.3 56.7,146.1 59.2,146.1 
			59.6,146.1 59.6,145.7 59.6,144.5 59.6,144.1 59.2,144.1 51.7,144.1 51.3,144.1 51.3,144.5 51.3,145.7 51.3,146.1 51.7,146.1 
			54.2,146.1 		"/>
		<polygon id="XMLID_911_" points="68.4,152.7 68.4,152.3 68.4,151.1 68.4,150.7 68,150.7 63.2,150.7 63.2,149.3 67.7,149.3 
			68.2,149.3 68.2,148.9 68.2,147.7 68.2,147.3 67.7,147.3 63.2,147.3 63.2,146.1 67.9,146.1 68.3,146.1 68.3,145.7 68.3,144.5 
			68.3,144.1 67.9,144.1 61.2,144.1 60.8,144.1 60.8,144.5 60.8,152.3 60.8,152.7 61.2,152.7 68,152.7 		"/>
		<polygon id="XMLID_912_" points="99.5,152.7 99.5,152.3 99.5,151.1 99.5,150.7 99.1,150.7 94.3,150.7 94.3,149.3 98.8,149.3 
			99.2,149.3 99.2,148.9 99.2,147.7 99.2,147.3 98.8,147.3 94.3,147.3 94.3,146.1 99,146.1 99.4,146.1 99.4,145.7 99.4,144.5 
			99.4,144.1 99,144.1 92.2,144.1 91.8,144.1 91.8,144.5 91.8,152.3 91.8,152.7 92.2,152.7 99.1,152.7 		"/>
		<path id="XMLID_915_" d="M104,152.7v-0.4v-2.2l0.9-0.8l2.7,3.3l0.1,0.1h0.2h2.2h0.9l-0.6-0.7l-3.6-4.3l3.3-2.9l0.8-0.7h-1.1h-2.2
			h-0.2l-0.1,0.1l-3.3,3v-2.7v-0.4h-0.4h-1.6h-0.4v0.4v7.8v0.4h0.4h1.6L104,152.7L104,152.7z"/>
		<polygon id="XMLID_916_" points="116.5,152.7 116.5,152.3 116.5,146.1 119,146.1 119.4,146.1 119.4,145.7 119.4,144.5 
			119.4,144.1 119,144.1 111.6,144.1 111.2,144.1 111.2,144.5 111.2,145.7 111.2,146.1 111.6,146.1 114.1,146.1 114.1,152.3 
			114.1,152.7 114.5,152.7 116.1,152.7 		"/>
		<path id="XMLID_919_" d="M138.1,152.6l-0.3-0.6l-3.5-7.8l-0.1-0.2H134H132h-0.3l-0.1,0.2l-3.5,7.8l-0.3,0.6h0.6h1.8h0.3l0.1-0.2
			l0.7-1.7h3.1l0.7,1.7l0.1,0.2h0.3h1.8H138.1z M132.1,149.1l0.9-2.2l0.9,2.2H132.1z"/>
		<path id="XMLID_923_" d="M155,147h-2.8v-2.6v-0.4h-0.4h-1.6h-0.4v0.4v7.8v0.4h0.4h4.8c1.8,0,2.9-1.1,2.9-2.8
			C157.9,148.4,157,147,155,147z M154.6,150.6h-2.3v-1.6h2.2c0.9,0,1,0.2,1,0.8C155.5,150.2,155.4,150.6,154.6,150.6z"/>
		<polygon id="XMLID_924_" points="166.3,144.1 165.9,144.1 165.9,144.5 165.9,147.1 161.9,147.1 161.9,144.5 161.9,144.1 
			161.5,144.1 159.9,144.1 159.4,144.1 159.4,144.5 159.4,152.2 159.4,152.6 159.9,152.6 161.5,152.6 161.9,152.6 161.9,152.2 
			161.9,149.1 165.9,149.1 165.9,152.2 165.9,152.6 166.3,152.6 167.9,152.6 168.4,152.6 168.4,152.2 168.4,144.5 168.4,144.1 
			167.9,144.1 		"/>
		<path id="XMLID_927_" d="M174.8,143.8c-3.1,0-5,1.7-5,4.5c0,2.8,1.9,4.5,5,4.5s5-1.7,5-4.5C179.8,145.5,178,143.8,174.8,143.8z
			 M174.8,145.9c1.6,0,2.5,0.9,2.5,2.5s-0.9,2.5-2.5,2.5s-2.5-0.9-2.5-2.5C172.4,146.8,173.3,145.9,174.8,145.9z"/>
		<path id="XMLID_934_" d="M187.9,144.1h-0.2l-0.1,0.1l-3.9,4.9v-4.7V144h-0.4h-1.6h-0.4v0.4v7.8v0.4h0.4h1.8h0.2l0.1-0.1l4-5v4.7
			v0.4h0.4h1.6h0.4v-0.4v-7.8V144h-0.4L187.9,144.1L187.9,144.1z"/>
		<path id="XMLID_937_" d="M73.8,148l0.1-1.9h2.6v6.2v0.4h0.4h1.6H79v-0.4v-7.8v-0.4h-0.4H72h-0.4l0,0.4c0,1.1-0.2,4.5-0.2,5.7
			c0,0.6-0.2,0.6-0.3,0.6c0,0-0.2,0-0.4-0.1l-0.6-0.3v0.7v1.4v0.3l0.3,0.1c0.4,0.1,0.7,0.2,1.2,0.2c0.5,0,1.4-0.1,1.9-1.1
			C73.7,151.1,73.8,150.2,73.8,148z"/>
		<path id="XMLID_940_" d="M83.8,151.8c0.4-0.8,0.4-1.7,0.5-3.8l0.1-1.9H87v6.2v0.4h0.4H89h0.4v-0.4v-7.8v-0.4H89h-6.6H82l0,0.4
			c0,1-0.1,4.4-0.2,5.7c0,0.6-0.2,0.6-0.3,0.6c0,0-0.2,0-0.4-0.1l-0.6-0.3v0.7v1.4v0.3l0.3,0.1c0.4,0.1,0.7,0.2,1.2,0.2
			C82.4,152.9,83.3,152.8,83.8,151.8z"/>
		<path id="XMLID_941_" d="M140.3,144.1h-0.4l0,0.4c0,1.1-0.1,4.5-0.2,5.7c0,0.6-0.2,0.6-0.3,0.6c0,0-0.2,0-0.4-0.1l-0.6-0.3v0.7
			v1.4v0.3l0.3,0.1c0.4,0.1,0.7,0.2,1.2,0.2c0.8,0,1.5-0.4,1.9-1.1c0.4-0.8,0.4-1.6,0.5-3.8V148l0.1-1.9h2.6v6.2v0.4h0.4h1.6h0.4
			v-0.4v-7.8v-0.4h-0.4L140.3,144.1L140.3,144.1z"/>
		<path id="XMLID_944_" d="M129.2,144.7l0.4-0.6h-0.7h-1.9h-0.2l-0.1,0.2l-1.9,3.5l-1.8-3.5l-0.1-0.2h-0.2h-2h-0.7l0.4,0.6
			c1,1.6,2.8,4.6,3.3,5.4c-0.3,0.5-0.5,0.6-0.8,0.6c-0.2,0-0.5-0.1-0.7-0.2l-0.1,0l-0.6-0.2v0.6v1.4v0.3l0.3,0.1l0.1,0
			c0.2,0.1,0.6,0.2,1.1,0.2c1.7,0,2.2-0.9,3.1-2.4C126.7,148.9,129.2,144.7,129.2,144.7z"/>
		<path id="XMLID_945_" d="M186.8,141.4l-0.1,0.3c0,0.2-0.5,0.4-1,0.4c-0.5,0-0.9-0.2-1-0.4l-0.1-0.3h-0.3h-0.6h-0.4v0.4
			c0,1.2,0.9,2,2.4,2s2.4-0.8,2.4-2v-0.4h-0.4h-0.6H186.8z"/>
	</g>
</g>
</svg>
`;

export const zh = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 106.5 167" style="enable-background:new 0 0 106.5 167;" xml:space="preserve">
<g id="XMLID_590_">
	<g id="XMLID_110_">
		<g id="XMLID_243_">
			<path id="XMLID_252_" d="M0,101.3h4.1L7,112.4l0,0l3.1-11.1h4.1l3.1,11.1l0,0l2.9-11.1h4.1l-4.8,15.2h-4.3L12,105.7l0,0
				l-3.2,10.8h-4L0,101.3z"/>
			<path id="XMLID_250_" d="M28.7,101.3h4.1v15.2h-4.1C28.7,116.5,28.7,101.3,28.7,101.3z"/>
			<path id="XMLID_247_" d="M39,101.3h10.2c4.3,0,5.4,2.6,5.4,4.6c0,3.2-1.7,5.2-5.1,5.2h-6.4v5.4H39V101.3z M43.1,108.3h4.4
				c1.3,0,2.8,0,2.8-2.1c0-2-1.2-2.2-2.4-2.2h-4.8V108.3z"/>
			<path id="XMLID_244_" d="M68.4,100.8c8.2,0,9.5,4.9,9.5,8c0,3.2-1.3,8-9.5,8s-9.5-4.9-9.5-8C58.9,105.7,60.2,100.8,68.4,100.8z
				 M68.4,114c3.8,0,5.1-2.8,5.1-5.1c0-2.4-1.3-5.1-5.1-5.1c-3.7,0-5.1,2.8-5.1,5.1C63.3,111.2,64.6,114,68.4,114z"/>
		</g>
		<path id="XMLID_236_" d="M75.8,19.2c1.3-1.6,2-3.2,2-4.9c-0.1-5.8-7.9-10.5-20-14.2L57.3,0v0.5v1.6l0.3,0.1c6,1.5,10.8,3.2,14,5.1
			c3.3,1.9,5,3.9,4.9,6c0,1.5-0.8,2.9-2.4,4.3c-3.4-2.9-9.1-5.5-16.4-7.7l-0.4-0.1v0.5v1.6l0.3,0.1c6,1.5,10.8,3.2,14,5.1
			c0.7,0.4,1.3,0.8,1.8,1.2c-1.9,1.5-4.6,2.9-7.9,4.2c-2.3-1-4.9-1.9-7.7-2.7l-0.4-0.1V20v1.6l0.3,0.1c2,0.5,3.8,1,5.6,1.6
			c-8.4,2.9-20.3,5.3-34.4,6.9v5.1c14.4-1.9,26.6-4.8,35.3-8.4c1.4-0.6,2.7-1.2,4-1.8c1.4,0.6,2.6,1.2,3.6,1.8
			c0.7,0.4,1.3,0.8,1.8,1.2c-1.9,1.5-4.6,2.9-7.9,4.2c-2.3-1-4.9-1.9-7.7-2.7l-0.4-0.1V30v1.6l0.3,0.1c2,0.5,3.8,1,5.6,1.6
			c-8.4,2.9-20.3,5.3-34.4,6.9v5.1c14-2.3,26.3-5.2,34.9-8.8c1.4-0.6,2.7-1.2,4-1.8c1.4,0.6,2.6,1.2,3.6,1.8
			c0.7,0.4,1.3,0.8,1.8,1.2c-1.9,1.5-4.6,2.9-8,4.2c-2.3-1-4.9-1.9-7.7-2.7L57.3,39v0.5v1.6l0.3,0.1c2,0.5,3.8,1,5.6,1.6
			c-8.4,2.9-20.3,5.3-34.4,6.9v5.1c14.4-1.9,26.6-4.8,35.3-8.4c1.4-0.6,2.7-1.2,4-1.8c1.4,0.6,2.6,1.2,3.6,1.8
			c0.7,0.4,1.3,0.8,1.8,1.2c-1.9,1.5-4.6,2.9-8,4.2c-2.3-1-4.9-1.9-7.7-2.7L57.4,49v0.5v1.6l0.3,0.1c2,0.5,3.8,1,5.6,1.6
			c-8.4,2.9-20.3,5.3-34.4,6.9v5.1c14.4-1.9,26.6-4.8,35.3-8.4c1.4-0.6,2.7-1.2,4-1.8c1.4,0.6,2.6,1.2,3.6,1.8
			c0.7,0.4,1.3,0.8,1.8,1.2c-1.9,1.5-4.6,2.9-7.9,4.2c-2.3-1-4.9-1.9-7.7-2.7L57.6,59v0v1.6l0.3,0.1c2,0.5,3.8,1,5.6,1.6
			c-8.4,2.9-20.3,5.3-34.4,6.9v5.1c14.4-1.9,26.6-4.8,35.3-8.4c1.4-0.6,2.7-1.2,4-1.8c1.4,0.6,2.6,1.2,3.6,1.8
			c0.7,0.4,1.3,0.8,1.8,1.2c-2.2,1.7-5.6,3.4-10,4.9c-8.4,2.9-20.4,5.4-34.6,7v5c13.9-2,26.2-4.9,34.8-8.4c5.1-2.1,8.9-4.4,11.2-6.9
			c0.9,1,1.3,2.1,1.3,3.1c0.1,3.4-4.7,7-13.2,9.9c-8.4,2.9-20.4,5.4-34.6,7v5.1C43.1,91.9,55.3,89,64,85.4
			c8.6-3.6,13.7-7.8,13.8-12.5c0-1.7-0.7-3.3-2-4.8c1.3-1.6,2-3.2,2-4.9c0-1.7-0.7-3.3-2-4.8c1.3-1.6,2-3.2,2-4.9s-0.7-3.3-2-4.8
			c1.3-1.6,2-3.2,2-4.9s-0.7-3.3-2-4.8c1.3-1.6,2-3.2,2-4.9s-0.7-3.3-2-4.8c1.3-1.6,2-3.2,2-4.9C77.7,22.4,77,20.7,75.8,19.2z
			 M69.4,24.2c2.5-1.4,4.4-2.8,5.8-4.3c0.9,1,1.3,2.1,1.3,3.1c0,1.4-0.8,2.9-2.4,4.3C72.8,26.2,71.2,25.2,69.4,24.2z M76.5,62
			c0,1.5-0.8,2.9-2.4,4.3c-1.3-1.1-2.8-2.1-4.7-3.1c2.5-1.4,4.4-2.8,5.8-4.3C76.1,59.9,76.5,61,76.5,62z M76.5,52.2
			c0,1.4-0.8,2.9-2.4,4.3c-1.3-1.1-2.8-2.1-4.7-3.1c2.5-1.4,4.4-2.8,5.8-4.3C76.1,50.2,76.5,51.2,76.5,52.2z M76.5,42.5
			c0,1.5-0.8,2.9-2.4,4.3c-1.3-1.1-2.9-2.1-4.7-3.1c2.5-1.4,4.4-2.8,5.8-4.3C76.1,40.4,76.5,41.4,76.5,42.5z M76.5,32.7
			c0,1.4-0.8,2.9-2.4,4.3c-1.3-1.1-2.8-2.1-4.7-3.1c2.5-1.4,4.4-2.8,5.8-4.3C76.1,30.6,76.5,31.7,76.5,32.7z"/>
	</g>
	<g id="XMLID_112_">
		<path id="XMLID_173_" d="M41,165.2v-8.9V156h-0.3h-5.3h-0.3v0.3v8.9h-1.6h-0.3v0.3v0.7v0.3h0.3h9h0.3v-0.3v-0.7v-0.3h-0.3H41z
			 M39.8,165.2h-3.3v-2h3.3V165.2z M39.8,162h-3.3v-1.8h3.3V162z M39.8,159h-3.3v-1.7h3.3V159z"/>
		<path id="XMLID_195_" d="M54.6,156.4v0.3v5.7v0.3h0.3H61h0.3v-0.3v-5.7v-0.3H61h-6.1H54.6z M59.9,161.4h-4.1v-3.7h4.1V161.4z"/>
		<polygon id="XMLID_197_" points="54,165.1 54,164.9 54,164.2 54,163.9 53.7,164 48.2,165.4 47.8,165.5 48.1,165.8 48.6,166.5 
			48.7,166.6 48.8,166.6 53.8,165.2 		"/>
		<path id="XMLID_200_" d="M48.2,163.3l0.1,0.2l0.4,0.7l0.1,0.2H49c1.8-0.4,4.4-0.8,4.7-0.9H54v-0.3v-0.7v-0.3h-0.3l0,0
			c-0.1,0-1.5,0.3-2.8,0.5c2.1-2,2.8-3.6,3-3.9l0.1-0.3l-0.3-0.1l-0.7-0.3l-0.2-0.1l-0.1,0.2c-0.1,0.2-0.3,0.6-0.8,1.3l0,0
			c-0.3,0-1,0.1-1.7,0.2c1.1-1.5,1.9-3.1,2.1-3.6l0.1-0.3l-0.3-0.1l-0.7-0.2l-0.2-0.1l-0.1,0.2c-0.1,0.3-1.2,2.8-2.8,4.3l-0.2,0.2
			l0.1,0.2l0.5,0.7l0.1,0.2H49c0.5-0.1,1.1-0.2,1.6-0.3c-0.6,0.7-1.4,1.3-2.2,2L48.2,163.3z"/>
		<path id="XMLID_205_" d="M56.7,163.2l-0.2-0.2l-0.1,0.2c-0.6,1-1.9,2.1-3,2.6l-0.5,0.2l0.4,0.3l0.7,0.5l0.1,0.1l0.1-0.1
			c1.5-0.7,2.6-1.9,3.2-2.9l0.1-0.2l-0.2-0.1L56.7,163.2z"/>
		<path id="XMLID_206_" d="M59.3,163.2l-0.2-0.1l-0.1,0.1l-0.5,0.5l-0.3,0.3l0.3,0.2c1.2,0.6,2.4,1.6,3,2.6l0.2,0.3l0.2-0.2l0.5-0.5
			l0.2-0.2l-0.1-0.2C61.8,164.9,60.6,163.9,59.3,163.2z"/>
		<polygon id="XMLID_207_" points="34.5,164.5 34.5,164.3 34.5,163.6 34.5,163.3 34.1,163.3 28.7,164.8 28.3,164.9 28.5,165.2 
			29,165.9 29.2,166 29.3,166 34.3,164.6 		"/>
		<path id="XMLID_210_" d="M28.5,162.8l0.1,0.2l0.4,0.7l0.1,0.2h0.2c1.8-0.4,4.4-0.8,4.7-0.9h0.2v-0.3V162v-0.3h-0.3l0,0
			c-0.1,0-1.5,0.3-2.8,0.5c2-1.9,2.8-3.4,3-3.9l0.1-0.3l-0.3-0.1l-0.7-0.3l-0.2-0.1l-0.1,0.2c-0.1,0.1-0.3,0.6-0.8,1.3
			c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.1-0.9,0.1-1.5,0.2c1.1-1.4,1.8-3,2.1-3.6l0.1-0.3l-0.3-0.1l-0.7-0.2l-0.2-0.1l-0.1,0.2
			c-0.1,0.3-1.2,2.8-2.8,4.4l-0.2,0.2l0.1,0.2L29,161l0.1,0.2h0.2c0.4-0.1,0.8-0.1,1.3-0.2c-0.6,0.6-1.2,1.2-2,1.7L28.5,162.8z"/>
		<path id="XMLID_216_" d="M29.3,135.5v0.3h0.3h0.7h0.3v-0.3v-0.6h9.6h0.3v-0.3v-0.7v-0.3h-0.3h-9.6v-5.1h2.3v3.6v0.3h0.3h4.7h0.3
			v-0.3v-3.6h3h0.3v-0.3v-0.7v-0.3h-0.3h-3v-2.7v-0.3h-0.3h-0.7h-0.3v0.3v2.7h-2.7v-2.6v-0.3h-0.3h-0.7h-0.3v0.3v2.6h-2.3v-2V125
			h-0.3h-0.7h-0.3v0.3v2h-2H27v0.3v0.7v0.3h0.3h2V135.5z M34.1,128.5h2.7v2.6h-2.7V128.5z"/>
		<path id="XMLID_221_" d="M48.1,135.8h0.2c1-0.1,2.3-0.5,3.2-1.3c0.8-0.7,1.2-1.8,1.2-3.2V131h-0.3h-0.7h-0.1
			c0.5-0.4,0.8-0.8,0.9-1.1h2.1c0.2,0.3,0.5,0.7,0.9,1.1h-0.6h-0.3v0.3v4.2v0.3H55h0.7H56v-0.3v-4.1c0.3,0.2,0.5,0.4,0.9,0.5
			c1.1,0.5,2.3,0.8,3,1h0.2l0.1-0.1l0.5-0.6l0.3-0.4l-0.5-0.1c-0.5-0.1-1.9-0.3-3.1-0.9c-0.5-0.2-0.9-0.6-1.2-0.8h1.9h0.3v-0.3v-4.9
			v-0.3h-0.3H49h-0.3v0.3v4.9v0.3H49h2c-0.2,0.2-0.5,0.5-1,0.7c-1.2,0.6-2.6,0.9-3.3,1l-0.5,0.1l0.3,0.4l0.5,0.6l0.1,0.1h0.2
			c0.8-0.2,2.1-0.5,3.3-1.1c0.4-0.2,0.7-0.4,0.9-0.6v0.1c0,0.2,0,1.5-0.8,2.2c-0.4,0.4-1.1,0.7-1.9,0.8c-0.5,0.1-0.9,0.2-1,0.2
			l-0.5,0.1l0.3,0.4l0.5,0.7V135.8z M54.2,125.8h3v0.8h-3V125.8z M54.2,127.9h3v0.9h-3V127.9z M50,125.8h3v0.8h-3V125.8z M50,127.9
			h3v0.9h-3V127.9z"/>
		<path id="XMLID_227_" d="M58.3,147.7l-1.3-0.6l-0.3-0.1l-0.1,0.3l0,0c-0.7,1.4-1.8,2.3-3.2,3.2h-0.1l-0.4,0.2l0.4,0.3l1.2,0.9
			l0.2,0.1l0.2-0.1l0,0c1.6-1.1,2.7-2.2,3.6-3.7v-0.1l0.2-0.3L58.3,147.7L58.3,147.7z"/>
		<path id="XMLID_228_" d="M65,150.8c-0.8-1.5-1.5-2.6-2.8-3.7L62,147l-0.2,0.1l-1.2,0.6l-0.4,0.2l0.3,0.3l0.1,0.1
			c1.1,1.1,1.8,2.1,2.5,3.5l0,0l0.1,0.3l0.3-0.1l1.3-0.7l0.3-0.1L65,150.8L65,150.8z"/>
		<path id="XMLID_231_" d="M48,143.2v0.3v1.2v0.3h0.3h2.1v3.7c0,0.1,0,0.2-0.1,0.2h-0.1l-0.3,0.2l0.2,0.3l0.9,1l0.2,0.2l0.2-0.1
			l3-1.4l0.2-0.1v-0.2l0,0v-1.3V147l-0.4,0.2l-1.8,0.8v-4.4v-0.3h-0.3h-3.9L48,143.2L48,143.2z"/>
		<path id="XMLID_232_" d="M52.1,142.7L52.1,142.7l0.2,0.3l0.3-0.1l1.3-0.7l0.3-0.2l-0.2-0.3v-0.1c-0.7-1-1.6-1.8-2.5-2.2l-0.1-0.1
			l-0.2,0.1l-1.2,0.7l-0.5,0.3l0.5,0.3l0.1,0.1C50.9,141.1,51.6,141.8,52.1,142.7z"/>
		<path id="XMLID_235_" d="M55.4,139.9v0.3v6.3v0.3h0.3h7.5h0.3v-0.3v-0.1v-6.2v-0.3h-0.3h-7.5H55.4z M57.5,141.7h3.9v3.4h-3.9
			V141.7z"/>
		<path id="XMLID_256_" d="M80.3,144.7c0.3-0.3,0.5-0.6,0.7-1v-0.1l0.2-0.3l-0.3-0.1l-1.3-0.5l-0.3-0.1l-0.1,0.2l0,0
			c-0.3,0.6-0.9,1.2-1.6,1.8H76l0.1-0.1l0.2-0.2l-0.2-0.2c-0.7-0.7-1.2-1.1-2-1.5H74h-0.1l0,0h-0.1l-0.1,0.1l-1,0.7l-0.4,0.3
			l0.4,0.3h0.1c0.4,0.3,0.8,0.5,1,0.8h-2.7h-0.3v0.3v2.6c0,1.4-0.7,2.3-2.2,3h-0.1l-0.5,0.2l0.4,0.3l1.1,0.9l0.2,0.1l0.2-0.1l0,0
			l0,0c1.9-1,2.9-2.3,2.9-4.1v-1.5H84h0.3v-0.3v-0.1v-1V145v-0.3H84H80.3z"/>
		<path id="XMLID_259_" d="M78,140.7l0.1-0.1l0.2-0.2l-0.2-0.2c-0.5-0.5-1-0.9-1.9-1.5l0,0l-0.2-0.1l-0.2,0.1l-1,0.6l-0.4,0.3
			l0.4,0.3h0.1c0.4,0.2,0.8,0.5,1.1,0.8h-6.3h-0.3v0.3v1.1v0.1v0.3h0.3H84h0.3v-0.3V142v-1l0,0v-0.3H84H78z"/>
		<path id="XMLID_262_" d="M94.8,147.7l0.1,0.3l0.3-0.1l1.3-0.6l0.3-0.1l-0.2-0.3v-0.1c-0.5-1-1.1-1.6-1.9-2.2l-0.2-0.1l-0.2,0.1
			l-0.4,0.2v-0.6h1.7h0.3v-0.3v-1.2v-0.3h-0.3h-1.7v-3V139h-0.3h-1.5h-0.3v0.3v3H90h-0.3v0.3v1.2v0.3H90h1.7
			c-0.6,1.5-1.5,2.8-2.6,3.8L89,148l-0.3,0.3l0.3,0.2l1.1,0.7l0.2,0.1l0.2-0.1l0,0c0.6-0.4,1.1-1,1.4-1.6v4.1v0.3h0.3h1.5H94v-0.3
			v-5.2C94.2,146.8,94.5,147.2,94.8,147.7L94.8,147.7z"/>
		<path id="XMLID_265_" d="M106,150.6L106,150.6c-1.7-0.7-3.2-1.7-4.3-3c1.1-1.5,2-3.5,2.7-6l0,0l0,0v-1v-0.1v-0.3h-0.3h-8.7h-0.3
			v0.3v1.2v0.3h0.3h1.2l0.1,0.7c0.4,1.5,1.2,3.1,2.5,4.9c-1.2,1.2-2.7,2.1-4.6,2.9h-0.1l-0.5,0.2l0.4,0.3l1.1,1l0.2,0.1l0.2-0.1
			c1.8-0.7,3.3-1.7,4.6-3c1.1,1.1,2.5,2.2,4.2,3l0.2,0.1l0.2-0.1l1.1-0.9l0.4-0.3L106,150.6z M98.6,142h3.6c-0.5,1.6-1.1,3-1.7,4
			C99.7,144.9,99,143.5,98.6,142z"/>
		<path id="XMLID_268_" d="M29,152.4l0.2-0.1c1.3-0.7,3.1-1.8,4.2-3.5c1,0.4,1.9,1,2.7,1.8l0,0l0.2,0.2l0.2-0.2l0.9-0.8h0.1l0.3-0.2
			l-0.3-0.2c-1.2-1-2.3-1.8-3.4-2.2l0,0c0-0.2,0.1-0.4,0.1-0.7h2.7h0.3v-0.3V145v-0.3h-0.3h-2.6c0-0.5,0-1,0-1.3c0-0.2,0-0.3,0-0.4
			h2.3h0.3v-0.3v-1.2v-0.3h-0.3h-4.5c0.2-0.7,0.3-1.2,0.3-1.5v-0.1v-0.3h-0.3l-1.4-0.2l-0.3-0.1l-0.1,0.3v0.1
			c-0.3,1.6-0.9,2.9-2,4.1l-0.3,0.3l0.4,0.2l1.3,0.6l0.2,0.1l0.2-0.2c0.4-0.5,0.8-1,1.1-1.5h0.9c0,0.1,0,0.3,0,0.4
			c0,0.4,0,0.9,0,1.3h-3.7h-0.3v0.3v1.2v0.3h0.3h3.5c0,0.1,0,0.1,0,0.2c-0.2,1-1.1,2-2.3,2.9c-0.6,0.4-1.1,0.7-1.4,0.9
			c-0.2,0.1-0.4,0.2-0.5,0.2l-0.4,0.2l0.3,0.3l1,1.1L29,152.4z"/>
		<path id="XMLID_271_" d="M38.4,140.4h-0.3v0.3v10.1v0.3h0.3h1.4h0.3v-0.3v-0.7h2v0.7v0.1v0.3h0.3H44h0.3v-0.3v-0.1v-10v-0.3H44
			h-5.6V140.4z M42.2,148.3h-2v-6.1h2V148.3z"/>
	</g>
</g>
</svg>
`;

export const de = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 238.4 167" style="enable-background:new 0 0 238.4 167;" xml:space="preserve">
<path d="M0,105.9h4.3l3.1,11.6l0,0l3.3-11.6H15l3.3,11.6l0,0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3l0,0l-3.4,11.3H5L0,105.9z"/>
<path d="M30.1,105.9h4.3v15.9h-4.3C30.1,121.8,30.1,105.9,30.1,105.9z"/>
<path d="M40.8,105.9h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4h-6.7v5.7h-4.3V105.9z M45.1,113.3h4.6c1.3,0,2.9,0,2.9-2.2
	s-1.3-2.3-2.5-2.3h-5V113.3z"/>
<path d="M71.5,105.5c8.6,0,9.9,5.1,9.9,8.4s-1.3,8.4-9.9,8.4s-9.9-5.1-9.9-8.4S63,105.5,71.5,105.5z M71.5,119.3
	c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S67.6,119.3,71.5,119.3z"/>
<path d="M79.2,20.1c1.3-1.4,2-3.3,2.1-5.2c-0.1-6-8.2-10.9-21-14.8L59.9,0v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4s5.2,4.1,5.2,6.2
	c0,1.5-0.8,3.1-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L60,10.2v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3
	c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4
	c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.2l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V47c15.1-2,27.9-5,36.9-8.8
	c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.5,1.2,3.7,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
	V43l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9
	c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L59.9,51v2.1l0.3,0.1
	c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9
	c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L60,61.2v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
	c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.9-5,36.9-8.8c5.3-2.2,9.3-4.6,11.8-7.2c0.8,0.9,1.3,2,1.3,3.3
	c0.1,3.6-4.9,7.3-13.8,10.4S45.2,91,30.3,92.7V98c15.1-2,27.9-5,36.9-8.8c9-3.7,14.4-8.1,14.4-13.1c-0.1-1.9-0.8-3.7-2.1-5.1
	c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5.1c1.2-1.5,1.9-3.3,2-5.2
	c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2C81.2,23.3,80.5,21.5,79.2,20.1z
	 M72.5,25.3c2.3-1.2,4.3-2.7,6.1-4.5c0.8,0.9,1.3,2,1.3,3.3c0,1.5-0.8,3.1-2.5,4.5C75.9,27.3,74.3,26.2,72.5,25.3z M80,64.8
	c0,1.5-0.8,3.1-2.5,4.5C76,68,74.3,66.9,72.6,66c2.3-1.2,4.3-2.7,6.1-4.5C79.5,62.5,80,63.6,80,64.8L80,64.8z M80,54.6
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,52.3,80,53.4,80,54.6L80,54.6z M80,44.4
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,42.1,80,43.2,80,44.4L80,44.4z M80,34.2
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,31.9,80,33,80,34.2L80,34.2z"/>
<path d="M29.1,130.7h1.8l1.7,6l0,0l1.9-6h1.7l1.9,6l0,0l1.8-6h1.7l-2.6,7.8h-1.7l-1.9-6l0,0l-1.9,6h-1.8L29.1,130.7z"/>
<path d="M43.3,134.6c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C44.6,138.6,43.3,136.7,43.3,134.6z M50.7,134.6
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C50.7,134.8,50.7,134.7,50.7,134.6z"/>
<path d="M54.8,130.7h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.8-0.5,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L54.8,130.7z M59.2,134.1c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L59.2,134.1L59.2,134.1z"/>
<path d="M64.9,130.7h1.6v6.5h4.7v1.3h-6.3C64.9,138.5,64.9,130.7,64.9,130.7z"/>
<path d="M73.4,130.7h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6V130.7z M75,137.2h2.3c1.2,0,2.4-0.4,2.4-2.7
	c0-2.5-1.6-2.7-2.5-2.7H75V137.2z"/>
<path d="M30,144.8h2.6v7.8H30V144.8z"/>
<path d="M35.4,144.8h3l3.6,4.9l0,0v-4.9h2.5v7.8h-2.9l-3.7-4.9l0,0v4.9h-2.6v-7.8H35.4z"/>
<path d="M46.5,144.8h8.3v1.7H52v6h-2.6v-6h-2.8v-1.7H46.5z"/>
<path d="M56.8,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8C56.8,152.6,56.8,144.8,56.8,144.8z"/>
<path d="M66.9,144.8h2.6v5.9h4.7v1.8h-7.3L66.9,144.8z"/>
<path d="M76.4,144.8H79v5.9h4.8v1.8h-7.3L76.4,144.8L76.4,144.8z"/>
<path d="M85.9,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M105.4,149.6c-0.3,1.7-1.2,3.3-4.9,3.3c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.5,4.9,3.3h-2.9
	c-0.2-0.9-1-1.6-2-1.5c-1.6,0-2.2,1.1-2.2,2.4c0,1.3,0.5,2.4,2.2,2.4c1,0.1,1.9-0.5,2-1.5L105.4,149.6z"/>
<path d="M106.9,144.8h8.3v1.7h-2.9v6h-2.6v-6h-2.8V144.8z"/>
<path d="M117.1,144.8h2.6v4.6c0,1.3,0.6,1.6,1.8,1.6c1.2,0,1.8-0.4,1.8-1.6v-4.6h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3V144.8z"/>
<path d="M130.8,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L130.8,144.8z M132.3,146.8L132.3,146.8l-1.1,2.9h2.2
	L132.3,146.8z"/>
<path d="M139,144.8h2.6v5.9h4.7v1.8H139V144.8z"/>
<path d="M153.9,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L153.9,144.8L153.9,144.8z M156.5,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M164.8,144.8h6.3c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4H171
	c-0.1-0.4-0.2-0.8-0.1-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6L164.8,144.8z M167.4,148.3h2.4c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.4V148.3z"/>
<path d="M180.8,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1c-2.7,0-5.1-0.9-5.1-4.1S178,144.6,180.8,144.6z M180.8,151.1
	c1.3,0,2.4-1,2.5-2.3c0-1.3-1-2.4-2.3-2.5c-1.3,0-2.4,1-2.5,2.3c0,0,0,0,0,0.1c-0.1,1.2,0.7,2.2,1.9,2.4
	C180.5,151.1,180.6,151.1,180.8,151.1L180.8,151.1z"/>
<path d="M188.2,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L188.2,144.8L188.2,144.8z M190.8,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M199.1,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M209.2,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
	c-0.1-0.4-0.2-0.8-0.2-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6v-7.8H209.2z M211.8,148.3h2.5c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.5V148.3z"/>
<path d="M219.7,144.8h8.3v1.7h-2.8v6h-2.6v-6h-2.8L219.7,144.8L219.7,144.8z"/>
<path d="M232.3,150.2l-3.5-5.3h3l1.7,3.3l1.7-3.3h3l-3.5,5.3v2.4h-2.6L232.3,150.2z"/>
<path d="M29.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1S37,167,33.7,167S29.1,165.1,29.1,162.9z M36.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C36.6,163.2,36.6,163,36.6,162.9z"/>
<path d="M40.7,159h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.9-0.4,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L40.7,159L40.7,159z M45.1,162.5c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L45.1,162.5z"/>
<path d="M59,166.8h-1l-0.2-1c-0.8,0.8-1.8,1.3-3,1.2c-3.3,0-4.6-1.9-4.6-4.1c0-2.2,1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4.1,2.7H57
	c-0.3-1-1.3-1.6-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c-0.2,1.4,0.8,2.7,2.2,2.8c0.2,0,0.3,0,0.5,0c1.6,0,2.4-0.8,2.7-2H55v-1.2h4V166.8z"/>
<path d="M64.4,159h1.9l3.5,7.8H68l-0.8-1.9h-3.6l-0.8,1.9H61L64.4,159z M66.7,163.6l-1.3-3.3l-1.3,3.3H66.7z"/>
<path d="M71.7,159h1.9l4.6,5.8l0,0V159h1.6v7.8H78l-4.7-5.8l0,0v5.8h-1.6V159z"/>
<path d="M82.6,159h1.6v7.8h-1.6V159z"/>
<path d="M86.4,165.6l5.3-5.4h-5V159h7.1v1.2l-5.3,5.3H94v1.3h-7.5L86.4,165.6z"/>
<path d="M98.7,159h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.6l-0.8,1.9h-1.8L98.7,159z M101,163.6l-1.3-3.3l-1.3,3.3H101z"/>
<path d="M107.5,160.2h-2.9V159h7.5v1.2h-2.9v6.5h-1.6L107.5,160.2L107.5,160.2z"/>
<path d="M114.1,159h1.6v7.8h-1.6V159z"/>
<path d="M118.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1S118.1,165.1,118.1,162.9z M125.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C125.6,163.2,125.6,163,125.6,162.9
	L125.6,162.9z"/>
<path d="M129.6,159h1.9l4.6,5.8l0,0V159h1.6v7.8h-1.8l-4.7-5.8l0,0v5.8h-1.6V159z"/>
</svg>
`;

export const ja = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 238.4 167" style="enable-background:new 0 0 238.4 167;" xml:space="preserve">
<path d="M0,105.9h4.3l3.1,11.6l0,0l3.3-11.6H15l3.3,11.6l0,0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3l0,0l-3.4,11.3H5L0,105.9z"/>
<path d="M30.1,105.9h4.3v15.9h-4.3C30.1,121.8,30.1,105.9,30.1,105.9z"/>
<path d="M40.8,105.9h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4h-6.7v5.7h-4.3V105.9z M45.1,113.3h4.6c1.3,0,2.9,0,2.9-2.2
	s-1.3-2.3-2.5-2.3h-5V113.3z"/>
<path d="M71.5,105.5c8.6,0,9.9,5.1,9.9,8.4s-1.3,8.4-9.9,8.4s-9.9-5.1-9.9-8.4S63,105.5,71.5,105.5z M71.5,119.3
	c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S67.6,119.3,71.5,119.3z"/>
<path d="M79.2,20.1c1.3-1.4,2-3.3,2.1-5.2c-0.1-6-8.2-10.9-21-14.8L59.9,0v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4s5.2,4.1,5.2,6.2
	c0,1.5-0.8,3.1-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L60,10.2v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3
	c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4
	c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.2l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V47c15.1-2,27.9-5,36.9-8.8
	c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.5,1.2,3.7,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
	V43l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9
	c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L59.9,51v2.1l0.3,0.1
	c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9
	c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L60,61.2v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
	c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.9-5,36.9-8.8c5.3-2.2,9.3-4.6,11.8-7.2c0.8,0.9,1.3,2,1.3,3.3
	c0.1,3.6-4.9,7.3-13.8,10.4S45.2,91,30.3,92.7V98c15.1-2,27.9-5,36.9-8.8c9-3.7,14.4-8.1,14.4-13.1c-0.1-1.9-0.8-3.7-2.1-5.1
	c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5.1c1.2-1.5,1.9-3.3,2-5.2
	c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2C81.2,23.3,80.5,21.5,79.2,20.1z
	 M72.5,25.3c2.3-1.2,4.3-2.7,6.1-4.5c0.8,0.9,1.3,2,1.3,3.3c0,1.5-0.8,3.1-2.5,4.5C75.9,27.3,74.3,26.2,72.5,25.3z M80,64.8
	c0,1.5-0.8,3.1-2.5,4.5C76,68,74.3,66.9,72.6,66c2.3-1.2,4.3-2.7,6.1-4.5C79.5,62.5,80,63.6,80,64.8L80,64.8z M80,54.6
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,52.3,80,53.4,80,54.6L80,54.6z M80,44.4
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,42.1,80,43.2,80,44.4L80,44.4z M80,34.2
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,31.9,80,33,80,34.2L80,34.2z"/>
<path d="M29.1,130.7h1.8l1.7,6l0,0l1.9-6h1.7l1.9,6l0,0l1.8-6h1.7l-2.6,7.8h-1.7l-1.9-6l0,0l-1.9,6h-1.8L29.1,130.7z"/>
<path d="M43.3,134.6c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C44.6,138.6,43.3,136.7,43.3,134.6z M50.7,134.6
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C50.7,134.8,50.7,134.7,50.7,134.6z"/>
<path d="M54.8,130.7h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.8-0.5,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L54.8,130.7z M59.2,134.1c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L59.2,134.1L59.2,134.1z"/>
<path d="M64.9,130.7h1.6v6.5h4.7v1.3h-6.3C64.9,138.5,64.9,130.7,64.9,130.7z"/>
<path d="M73.4,130.7h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6V130.7z M75,137.2h2.3c1.2,0,2.4-0.4,2.4-2.7
	c0-2.5-1.6-2.7-2.5-2.7H75V137.2z"/>
<path d="M30,144.8h2.6v7.8H30V144.8z"/>
<path d="M35.4,144.8h3l3.6,4.9l0,0v-4.9h2.5v7.8h-2.9l-3.7-4.9l0,0v4.9h-2.6v-7.8H35.4z"/>
<path d="M46.5,144.8h8.3v1.7H52v6h-2.6v-6h-2.8v-1.7H46.5z"/>
<path d="M56.8,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8C56.8,152.6,56.8,144.8,56.8,144.8z"/>
<path d="M66.9,144.8h2.6v5.9h4.7v1.8h-7.3L66.9,144.8z"/>
<path d="M76.4,144.8H79v5.9h4.8v1.8h-7.3L76.4,144.8L76.4,144.8z"/>
<path d="M85.9,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M105.4,149.6c-0.3,1.7-1.2,3.3-4.9,3.3c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.5,4.9,3.3h-2.9
	c-0.2-0.9-1-1.6-2-1.5c-1.6,0-2.2,1.1-2.2,2.4c0,1.3,0.5,2.4,2.2,2.4c1,0.1,1.9-0.5,2-1.5L105.4,149.6z"/>
<path d="M106.9,144.8h8.3v1.7h-2.9v6h-2.6v-6h-2.8V144.8z"/>
<path d="M117.1,144.8h2.6v4.6c0,1.3,0.6,1.6,1.8,1.6c1.2,0,1.8-0.4,1.8-1.6v-4.6h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3V144.8z"/>
<path d="M130.8,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L130.8,144.8z M132.3,146.8L132.3,146.8l-1.1,2.9h2.2
	L132.3,146.8z"/>
<path d="M139,144.8h2.6v5.9h4.7v1.8H139V144.8z"/>
<path d="M153.9,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L153.9,144.8L153.9,144.8z M156.5,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M164.8,144.8h6.3c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4H171
	c-0.1-0.4-0.2-0.8-0.1-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6L164.8,144.8z M167.4,148.3h2.4c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.4V148.3z"/>
<path d="M180.8,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1c-2.7,0-5.1-0.9-5.1-4.1S178,144.6,180.8,144.6z M180.8,151.1
	c1.3,0,2.4-1,2.5-2.3c0-1.3-1-2.4-2.3-2.5c-1.3,0-2.4,1-2.5,2.3c0,0,0,0,0,0.1c-0.1,1.2,0.7,2.2,1.9,2.4
	C180.5,151.1,180.6,151.1,180.8,151.1L180.8,151.1z"/>
<path d="M188.2,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L188.2,144.8L188.2,144.8z M190.8,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M199.1,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M209.2,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
	c-0.1-0.4-0.2-0.8-0.2-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6v-7.8H209.2z M211.8,148.3h2.5c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.5V148.3z"/>
<path d="M219.7,144.8h8.3v1.7h-2.8v6h-2.6v-6h-2.8L219.7,144.8L219.7,144.8z"/>
<path d="M232.3,150.2l-3.5-5.3h3l1.7,3.3l1.7-3.3h3l-3.5,5.3v2.4h-2.6L232.3,150.2z"/>
<path d="M29.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1S37,167,33.7,167S29.1,165.1,29.1,162.9z M36.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C36.6,163.2,36.6,163,36.6,162.9z"/>
<path d="M40.7,159h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.9-0.4,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L40.7,159L40.7,159z M45.1,162.5c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L45.1,162.5z"/>
<path d="M59,166.8h-1l-0.2-1c-0.8,0.8-1.8,1.3-3,1.2c-3.3,0-4.6-1.9-4.6-4.1c0-2.2,1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4.1,2.7H57
	c-0.3-1-1.3-1.6-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c-0.2,1.4,0.8,2.7,2.2,2.8c0.2,0,0.3,0,0.5,0c1.6,0,2.4-0.8,2.7-2H55v-1.2h4V166.8z"/>
<path d="M64.4,159h1.9l3.5,7.8H68l-0.8-1.9h-3.6l-0.8,1.9H61L64.4,159z M66.7,163.6l-1.3-3.3l-1.3,3.3H66.7z"/>
<path d="M71.7,159h1.9l4.6,5.8l0,0V159h1.6v7.8H78l-4.7-5.8l0,0v5.8h-1.6V159z"/>
<path d="M82.6,159h1.6v7.8h-1.6V159z"/>
<path d="M86.4,165.6l5.3-5.4h-5V159h7.1v1.2l-5.3,5.3H94v1.3h-7.5L86.4,165.6z"/>
<path d="M98.7,159h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.6l-0.8,1.9h-1.8L98.7,159z M101,163.6l-1.3-3.3l-1.3,3.3H101z"/>
<path d="M107.5,160.2h-2.9V159h7.5v1.2h-2.9v6.5h-1.6L107.5,160.2L107.5,160.2z"/>
<path d="M114.1,159h1.6v7.8h-1.6V159z"/>
<path d="M118.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1S118.1,165.1,118.1,162.9z M125.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C125.6,163.2,125.6,163,125.6,162.9
	L125.6,162.9z"/>
<path d="M129.6,159h1.9l4.6,5.8l0,0V159h1.6v7.8h-1.8l-4.7-5.8l0,0v5.8h-1.6V159z"/>
</svg>
`;

export const pt = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 238.4 167" style="enable-background:new 0 0 238.4 167;" xml:space="preserve">
<path d="M0,105.9h4.3l3.1,11.6l0,0l3.3-11.6H15l3.3,11.6l0,0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3l0,0l-3.4,11.3H5L0,105.9z"/>
<path d="M30.1,105.9h4.3v15.9h-4.3C30.1,121.8,30.1,105.9,30.1,105.9z"/>
<path d="M40.8,105.9h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4h-6.7v5.7h-4.3V105.9z M45.1,113.3h4.6c1.3,0,2.9,0,2.9-2.2
	s-1.3-2.3-2.5-2.3h-5V113.3z"/>
<path d="M71.5,105.5c8.6,0,9.9,5.1,9.9,8.4s-1.3,8.4-9.9,8.4s-9.9-5.1-9.9-8.4S63,105.5,71.5,105.5z M71.5,119.3
	c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S67.6,119.3,71.5,119.3z"/>
<path d="M79.2,20.1c1.3-1.4,2-3.3,2.1-5.2c-0.1-6-8.2-10.9-21-14.8L59.9,0v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4s5.2,4.1,5.2,6.2
	c0,1.5-0.8,3.1-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L60,10.2v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3
	c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4
	c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.2l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V47c15.1-2,27.9-5,36.9-8.8
	c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.5,1.2,3.7,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
	V43l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9
	c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L59.9,51v2.1l0.3,0.1
	c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9
	c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L60,61.2v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
	c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.9-5,36.9-8.8c5.3-2.2,9.3-4.6,11.8-7.2c0.8,0.9,1.3,2,1.3,3.3
	c0.1,3.6-4.9,7.3-13.8,10.4S45.2,91,30.3,92.7V98c15.1-2,27.9-5,36.9-8.8c9-3.7,14.4-8.1,14.4-13.1c-0.1-1.9-0.8-3.7-2.1-5.1
	c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5.1c1.2-1.5,1.9-3.3,2-5.2
	c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2C81.2,23.3,80.5,21.5,79.2,20.1z
	 M72.5,25.3c2.3-1.2,4.3-2.7,6.1-4.5c0.8,0.9,1.3,2,1.3,3.3c0,1.5-0.8,3.1-2.5,4.5C75.9,27.3,74.3,26.2,72.5,25.3z M80,64.8
	c0,1.5-0.8,3.1-2.5,4.5C76,68,74.3,66.9,72.6,66c2.3-1.2,4.3-2.7,6.1-4.5C79.5,62.5,80,63.6,80,64.8L80,64.8z M80,54.6
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,52.3,80,53.4,80,54.6L80,54.6z M80,44.4
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,42.1,80,43.2,80,44.4L80,44.4z M80,34.2
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,31.9,80,33,80,34.2L80,34.2z"/>
<path d="M29.1,130.7h1.8l1.7,6l0,0l1.9-6h1.7l1.9,6l0,0l1.8-6h1.7l-2.6,7.8h-1.7l-1.9-6l0,0l-1.9,6h-1.8L29.1,130.7z"/>
<path d="M43.3,134.6c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C44.6,138.6,43.3,136.7,43.3,134.6z M50.7,134.6
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C50.7,134.8,50.7,134.7,50.7,134.6z"/>
<path d="M54.8,130.7h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.8-0.5,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L54.8,130.7z M59.2,134.1c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L59.2,134.1L59.2,134.1z"/>
<path d="M64.9,130.7h1.6v6.5h4.7v1.3h-6.3C64.9,138.5,64.9,130.7,64.9,130.7z"/>
<path d="M73.4,130.7h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6V130.7z M75,137.2h2.3c1.2,0,2.4-0.4,2.4-2.7
	c0-2.5-1.6-2.7-2.5-2.7H75V137.2z"/>
<path d="M30,144.8h2.6v7.8H30V144.8z"/>
<path d="M35.4,144.8h3l3.6,4.9l0,0v-4.9h2.5v7.8h-2.9l-3.7-4.9l0,0v4.9h-2.6v-7.8H35.4z"/>
<path d="M46.5,144.8h8.3v1.7H52v6h-2.6v-6h-2.8v-1.7H46.5z"/>
<path d="M56.8,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8C56.8,152.6,56.8,144.8,56.8,144.8z"/>
<path d="M66.9,144.8h2.6v5.9h4.7v1.8h-7.3L66.9,144.8z"/>
<path d="M76.4,144.8H79v5.9h4.8v1.8h-7.3L76.4,144.8L76.4,144.8z"/>
<path d="M85.9,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M105.4,149.6c-0.3,1.7-1.2,3.3-4.9,3.3c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.5,4.9,3.3h-2.9
	c-0.2-0.9-1-1.6-2-1.5c-1.6,0-2.2,1.1-2.2,2.4c0,1.3,0.5,2.4,2.2,2.4c1,0.1,1.9-0.5,2-1.5L105.4,149.6z"/>
<path d="M106.9,144.8h8.3v1.7h-2.9v6h-2.6v-6h-2.8V144.8z"/>
<path d="M117.1,144.8h2.6v4.6c0,1.3,0.6,1.6,1.8,1.6c1.2,0,1.8-0.4,1.8-1.6v-4.6h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3V144.8z"/>
<path d="M130.8,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L130.8,144.8z M132.3,146.8L132.3,146.8l-1.1,2.9h2.2
	L132.3,146.8z"/>
<path d="M139,144.8h2.6v5.9h4.7v1.8H139V144.8z"/>
<path d="M153.9,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L153.9,144.8L153.9,144.8z M156.5,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M164.8,144.8h6.3c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4H171
	c-0.1-0.4-0.2-0.8-0.1-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6L164.8,144.8z M167.4,148.3h2.4c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.4V148.3z"/>
<path d="M180.8,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1c-2.7,0-5.1-0.9-5.1-4.1S178,144.6,180.8,144.6z M180.8,151.1
	c1.3,0,2.4-1,2.5-2.3c0-1.3-1-2.4-2.3-2.5c-1.3,0-2.4,1-2.5,2.3c0,0,0,0,0,0.1c-0.1,1.2,0.7,2.2,1.9,2.4
	C180.5,151.1,180.6,151.1,180.8,151.1L180.8,151.1z"/>
<path d="M188.2,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L188.2,144.8L188.2,144.8z M190.8,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M199.1,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M209.2,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
	c-0.1-0.4-0.2-0.8-0.2-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6v-7.8H209.2z M211.8,148.3h2.5c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.5V148.3z"/>
<path d="M219.7,144.8h8.3v1.7h-2.8v6h-2.6v-6h-2.8L219.7,144.8L219.7,144.8z"/>
<path d="M232.3,150.2l-3.5-5.3h3l1.7,3.3l1.7-3.3h3l-3.5,5.3v2.4h-2.6L232.3,150.2z"/>
<path d="M29.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1S37,167,33.7,167S29.1,165.1,29.1,162.9z M36.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C36.6,163.2,36.6,163,36.6,162.9z"/>
<path d="M40.7,159h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.9-0.4,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L40.7,159L40.7,159z M45.1,162.5c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L45.1,162.5z"/>
<path d="M59,166.8h-1l-0.2-1c-0.8,0.8-1.8,1.3-3,1.2c-3.3,0-4.6-1.9-4.6-4.1c0-2.2,1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4.1,2.7H57
	c-0.3-1-1.3-1.6-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c-0.2,1.4,0.8,2.7,2.2,2.8c0.2,0,0.3,0,0.5,0c1.6,0,2.4-0.8,2.7-2H55v-1.2h4V166.8z"/>
<path d="M64.4,159h1.9l3.5,7.8H68l-0.8-1.9h-3.6l-0.8,1.9H61L64.4,159z M66.7,163.6l-1.3-3.3l-1.3,3.3H66.7z"/>
<path d="M71.7,159h1.9l4.6,5.8l0,0V159h1.6v7.8H78l-4.7-5.8l0,0v5.8h-1.6V159z"/>
<path d="M82.6,159h1.6v7.8h-1.6V159z"/>
<path d="M86.4,165.6l5.3-5.4h-5V159h7.1v1.2l-5.3,5.3H94v1.3h-7.5L86.4,165.6z"/>
<path d="M98.7,159h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.6l-0.8,1.9h-1.8L98.7,159z M101,163.6l-1.3-3.3l-1.3,3.3H101z"/>
<path d="M107.5,160.2h-2.9V159h7.5v1.2h-2.9v6.5h-1.6L107.5,160.2L107.5,160.2z"/>
<path d="M114.1,159h1.6v7.8h-1.6V159z"/>
<path d="M118.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1S118.1,165.1,118.1,162.9z M125.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C125.6,163.2,125.6,163,125.6,162.9
	L125.6,162.9z"/>
<path d="M129.6,159h1.9l4.6,5.8l0,0V159h1.6v7.8h-1.8l-4.7-5.8l0,0v5.8h-1.6V159z"/>
</svg>
`;

export const ko = html`
<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 238.4 167" style="enable-background:new 0 0 238.4 167;" xml:space="preserve">
<path d="M0,105.9h4.3l3.1,11.6l0,0l3.3-11.6H15l3.3,11.6l0,0l3.1-11.6h4.3l-5,15.9h-4.5l-3.3-11.3l0,0l-3.4,11.3H5L0,105.9z"/>
<path d="M30.1,105.9h4.3v15.9h-4.3C30.1,121.8,30.1,105.9,30.1,105.9z"/>
<path d="M40.8,105.9h10.7c4.5,0,5.6,2.7,5.6,4.8c0,3.4-1.8,5.4-5.3,5.4h-6.7v5.7h-4.3V105.9z M45.1,113.3h4.6c1.3,0,2.9,0,2.9-2.2
	s-1.3-2.3-2.5-2.3h-5V113.3z"/>
<path d="M71.5,105.5c8.6,0,9.9,5.1,9.9,8.4s-1.3,8.4-9.9,8.4s-9.9-5.1-9.9-8.4S63,105.5,71.5,105.5z M71.5,119.3
	c3.9,0,5.3-2.9,5.3-5.4s-1.4-5.4-5.3-5.4s-5.3,2.9-5.3,5.4S67.6,119.3,71.5,119.3z"/>
<path d="M79.2,20.1c1.3-1.4,2-3.3,2.1-5.2c-0.1-6-8.2-10.9-21-14.8L59.9,0v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4s5.2,4.1,5.2,6.2
	c0,1.5-0.8,3.1-2.5,4.5c-3.6-3.1-9.5-5.7-17.1-8L60,10.2v2.1l0.3,0.1c6.3,1.6,11.3,3.4,14.7,5.4c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3
	c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4
	c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1v2.2l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2V47c15.1-2,27.9-5,36.9-8.8
	c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.5,1.2,3.7,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.4-1-5.1-1.9-8-2.8l-0.5-0.1
	V43l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9
	c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L59.9,51v2.1l0.3,0.1
	c2.1,0.5,4,1.1,5.8,1.6c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.1-1.9c1.3,0.5,2.6,1.2,3.8,1.9
	c0.6,0.4,1.3,0.8,1.9,1.2c-2.6,1.8-5.4,3.3-8.3,4.4c-2.6-1.1-5.3-2-8-2.8L60,61.2v2.1l0.3,0.1c2.1,0.5,4,1.1,5.8,1.6
	c-8.8,3-21.2,5.5-35.9,7.2v5.3c15.1-2,27.9-5,36.9-8.8c1.5-0.6,2.9-1.2,4.2-1.9c1.3,0.5,2.6,1.2,3.8,1.9c0.6,0.4,1.3,0.8,1.9,1.2
	c-2.3,1.8-5.9,3.6-10.5,5.2c-8.8,3.1-21.3,5.6-36.2,7.3v5.3c15.1-2,27.9-5,36.9-8.8c5.3-2.2,9.3-4.6,11.8-7.2c0.8,0.9,1.3,2,1.3,3.3
	c0.1,3.6-4.9,7.3-13.8,10.4S45.2,91,30.3,92.7V98c15.1-2,27.9-5,36.9-8.8c9-3.7,14.4-8.1,14.4-13.1c-0.1-1.9-0.8-3.7-2.1-5.1
	c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5.1c1.2-1.5,1.9-3.3,2-5.2
	c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2c-0.1-1.9-0.8-3.7-2.1-5c1.3-1.4,2-3.3,2.1-5.2C81.2,23.3,80.5,21.5,79.2,20.1z
	 M72.5,25.3c2.3-1.2,4.3-2.7,6.1-4.5c0.8,0.9,1.3,2,1.3,3.3c0,1.5-0.8,3.1-2.5,4.5C75.9,27.3,74.3,26.2,72.5,25.3z M80,64.8
	c0,1.5-0.8,3.1-2.5,4.5C76,68,74.3,66.9,72.6,66c2.3-1.2,4.3-2.7,6.1-4.5C79.5,62.5,80,63.6,80,64.8L80,64.8z M80,54.6
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,52.3,80,53.4,80,54.6L80,54.6z M80,44.4
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,42.1,80,43.2,80,44.4L80,44.4z M80,34.2
	c0,1.5-0.8,3.1-2.5,4.5c-1.5-1.3-3.2-2.4-4.9-3.3c2.3-1.2,4.3-2.7,6.1-4.5C79.5,31.9,80,33,80,34.2L80,34.2z"/>
<path d="M29.1,130.7h1.8l1.7,6l0,0l1.9-6h1.7l1.9,6l0,0l1.8-6h1.7l-2.6,7.8h-1.7l-1.9-6l0,0l-1.9,6h-1.8L29.1,130.7z"/>
<path d="M43.3,134.6c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1C44.6,138.6,43.3,136.7,43.3,134.6z M50.7,134.6
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C50.7,134.8,50.7,134.7,50.7,134.6z"/>
<path d="M54.8,130.7h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.8-0.5,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L54.8,130.7z M59.2,134.1c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L59.2,134.1L59.2,134.1z"/>
<path d="M64.9,130.7h1.6v6.5h4.7v1.3h-6.3C64.9,138.5,64.9,130.7,64.9,130.7z"/>
<path d="M73.4,130.7h4.2c3,0,3.8,1.7,3.8,4c0,1.7-0.8,3.8-3.4,3.8h-4.6V130.7z M75,137.2h2.3c1.2,0,2.4-0.4,2.4-2.7
	c0-2.5-1.6-2.7-2.5-2.7H75V137.2z"/>
<path d="M30,144.8h2.6v7.8H30V144.8z"/>
<path d="M35.4,144.8h3l3.6,4.9l0,0v-4.9h2.5v7.8h-2.9l-3.7-4.9l0,0v4.9h-2.6v-7.8H35.4z"/>
<path d="M46.5,144.8h8.3v1.7H52v6h-2.6v-6h-2.8v-1.7H46.5z"/>
<path d="M56.8,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8C56.8,152.6,56.8,144.8,56.8,144.8z"/>
<path d="M66.9,144.8h2.6v5.9h4.7v1.8h-7.3L66.9,144.8z"/>
<path d="M76.4,144.8H79v5.9h4.8v1.8h-7.3L76.4,144.8L76.4,144.8z"/>
<path d="M85.9,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M105.4,149.6c-0.3,1.7-1.2,3.3-4.9,3.3c-3.3,0-4.9-1.4-4.9-4.1c0-2.6,1.5-4.1,4.9-4.1c2.4,0,4.3,0.5,4.9,3.3h-2.9
	c-0.2-0.9-1-1.6-2-1.5c-1.6,0-2.2,1.1-2.2,2.4c0,1.3,0.5,2.4,2.2,2.4c1,0.1,1.9-0.5,2-1.5L105.4,149.6z"/>
<path d="M106.9,144.8h8.3v1.7h-2.9v6h-2.6v-6h-2.8V144.8z"/>
<path d="M117.1,144.8h2.6v4.6c0,1.3,0.6,1.6,1.8,1.6c1.2,0,1.8-0.4,1.8-1.6v-4.6h2.6v5c0,2.1-1.6,3-4.4,3s-4.4-0.8-4.4-3V144.8z"/>
<path d="M130.8,144.8h3.2l3.3,7.8h-2.8l-0.5-1.3h-3.4l-0.5,1.3h-2.8L130.8,144.8z M132.3,146.8L132.3,146.8l-1.1,2.9h2.2
	L132.3,146.8z"/>
<path d="M139,144.8h2.6v5.9h4.7v1.8H139V144.8z"/>
<path d="M153.9,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L153.9,144.8L153.9,144.8z M156.5,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M164.8,144.8h6.3c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4H171
	c-0.1-0.4-0.2-0.8-0.1-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6L164.8,144.8z M167.4,148.3h2.4c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.4V148.3z"/>
<path d="M180.8,144.6c2.7,0,5.1,0.9,5.1,4.1c0,3.2-2.3,4.1-5.1,4.1c-2.7,0-5.1-0.9-5.1-4.1S178,144.6,180.8,144.6z M180.8,151.1
	c1.3,0,2.4-1,2.5-2.3c0-1.3-1-2.4-2.3-2.5c-1.3,0-2.4,1-2.5,2.3c0,0,0,0,0,0.1c-0.1,1.2,0.7,2.2,1.9,2.4
	C180.5,151.1,180.6,151.1,180.8,151.1L180.8,151.1z"/>
<path d="M188.2,144.8h5.7c2.1,0,2.9,1.1,2.9,2.6c0,1.3-0.7,2.6-2.7,2.6h-3.3v2.6h-2.6L188.2,144.8L188.2,144.8z M190.8,148.4h2.3
	c0.7,0,1-0.3,1-1s-0.3-1-1-1h-2.3V148.4z"/>
<path d="M199.1,144.8h7.8v1.7h-5.2v1.4h4.9v1.6h-4.9v1.4h5.2v1.7h-7.8V144.8z"/>
<path d="M209.2,144.8h6.2c1.9,0,2.6,0.9,2.6,2.2c0.1,0.8-0.4,1.6-1.2,1.8l0,0c0.6,0.2,1,0.8,0.9,1.4c0.1,1.5,0.2,2,0.5,2.4h-2.8
	c-0.1-0.4-0.2-0.8-0.2-1.2c0-1.2-0.4-1.4-1.1-1.4h-2.4v2.6h-2.6v-7.8H209.2z M211.8,148.3h2.5c0.6,0,1-0.3,1-0.9
	c0-0.5-0.3-0.8-1-0.8h-2.5V148.3z"/>
<path d="M219.7,144.8h8.3v1.7h-2.8v6h-2.6v-6h-2.8L219.7,144.8L219.7,144.8z"/>
<path d="M232.3,150.2l-3.5-5.3h3l1.7,3.3l1.7-3.3h3l-3.5,5.3v2.4h-2.6L232.3,150.2z"/>
<path d="M29.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1S37,167,33.7,167S29.1,165.1,29.1,162.9z M36.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C36.6,163.2,36.6,163,36.6,162.9z"/>
<path d="M40.7,159h4.9c2,0,2.6,0.9,2.6,2.1c0.1,0.9-0.4,1.6-1.3,1.9l0,0c0.9,0.2,1.1,1,1.1,2.1c0,0.6,0.1,1.1,0.3,1.6h-1.8
	c-0.2-0.4-0.2-0.8-0.2-1.2c0-1.1-0.2-1.9-1.3-1.9h-2.8v3.1h-1.6L40.7,159L40.7,159z M45.1,162.5c0.9,0,1.4-0.3,1.4-1.1
	c0-0.9-0.5-1.1-1.4-1.1h-2.8v2.3L45.1,162.5z"/>
<path d="M59,166.8h-1l-0.2-1c-0.8,0.8-1.8,1.3-3,1.2c-3.3,0-4.6-1.9-4.6-4.1c0-2.2,1.4-4.1,4.5-4.1c2.4,0,3.9,1.2,4.1,2.7H57
	c-0.3-1-1.3-1.6-2.3-1.5c-2,0-2.8,1.2-2.8,2.9c-0.2,1.4,0.8,2.7,2.2,2.8c0.2,0,0.3,0,0.5,0c1.6,0,2.4-0.8,2.7-2H55v-1.2h4V166.8z"/>
<path d="M64.4,159h1.9l3.5,7.8H68l-0.8-1.9h-3.6l-0.8,1.9H61L64.4,159z M66.7,163.6l-1.3-3.3l-1.3,3.3H66.7z"/>
<path d="M71.7,159h1.9l4.6,5.8l0,0V159h1.6v7.8H78l-4.7-5.8l0,0v5.8h-1.6V159z"/>
<path d="M82.6,159h1.6v7.8h-1.6V159z"/>
<path d="M86.4,165.6l5.3-5.4h-5V159h7.1v1.2l-5.3,5.3H94v1.3h-7.5L86.4,165.6z"/>
<path d="M98.7,159h1.9l3.5,7.8h-1.8l-0.8-1.9h-3.6l-0.8,1.9h-1.8L98.7,159z M101,163.6l-1.3-3.3l-1.3,3.3H101z"/>
<path d="M107.5,160.2h-2.9V159h7.5v1.2h-2.9v6.5h-1.6L107.5,160.2L107.5,160.2z"/>
<path d="M114.1,159h1.6v7.8h-1.6V159z"/>
<path d="M118.1,162.9c0-2.2,1.3-4.1,4.6-4.1s4.6,1.9,4.6,4.1s-1.3,4.1-4.6,4.1S118.1,165.1,118.1,162.9z M125.6,162.9
	c0-1.6-1.3-2.9-2.9-2.9s-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.5,0.1,2.8-1,2.9-2.5C125.6,163.2,125.6,163,125.6,162.9
	L125.6,162.9z"/>
<path d="M129.6,159h1.9l4.6,5.8l0,0V159h1.6v7.8h-1.8l-4.7-5.8l0,0v5.8h-1.6V159z"/>
</svg>
`;